import { MessageState, selectAll, selectedMessageId } from './message.reducers';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Message } from 'src/app/entities/message';

export const messageFeatureSelector = createFeatureSelector<MessageState>('messages');

export const getAllMessages = createSelector(
  messageFeatureSelector,
  selectAll
);
export const getSelectedMessageId = createSelector(
  messageFeatureSelector,
  state => state.selectedMessageId
);

export const getSelectedMessage = createSelector(
  messageFeatureSelector,
  state => state.selectedMessageId != null ? state.entities[state.selectedMessageId] : null
);

export const areMessagesLoaded = createSelector(
  messageFeatureSelector,
  state => state.messagesLoaded
);