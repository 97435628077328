import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { Observable } from 'rxjs';
import { Coupon } from 'src/app/entities/coupon';
import { User } from 'src/app/entities/user';
import { AppState } from 'src/app/store/reducers';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { startDeleteCoupon, startUpdateCoupon } from '../store/coupon.actions';
import { getAllCoupons } from '../store/coupon.selectors';

@Component({
  selector: 'app-list-coupon',
  templateUrl: './list-coupon.component.html',
  styleUrls: ['./list-coupon.component.scss']
})
export class ListCouponComponent implements OnInit {

  @ViewChild('table')
  smartTable: Ng2SmartTableComponent;
  constructor(private store: Store<AppState>,) {
  }
  public actions = []

  public settings = {
    actions: {
      position: 'left',
      custom: this.actions,
      edit: false,
      delete: false,
      add: false
    },
    columns: {

      title: {
        title: 'Title'
      },
      code: {
        title: 'Code'
      },
      discount: {
        title: 'Discount'
      },
      discount_type: {
        title: 'Discount Type'
      },
      enabled: {
        title: 'Status'
      },
      creator: {
        title: 'Created by',
        valuePrepareFunction: (creator: User) => {
          return creator?.extra?.full_name;
        }
      },
      editor: {
        title: 'Updated by',
        valuePrepareFunction: (editor: User) => {
          return editor?.extra?.full_name;
        }
      },
      created_at: {
        title: 'Created at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        }
      },
      updated_at: {
        title: 'Updated at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        }
      },
    },
  };
  ngOnInit() {
    this.$user.subscribe(user => {
      let permissions = {
        create: user.role.permissions.find(permission => permission.name == 'create_coupon').is_permitted,
        update: user.role.permissions.find(permission => permission.name == 'update_coupon').is_permitted,
        delete: user.role.permissions.find(permission => permission.name == 'delete_coupon').is_permitted,
        showAll: user.role.permissions.find(permission => permission.name == 'show_all_coupon').is_permitted,
      };
      if (!permissions.showAll) {
        window.history.back();
      }
      if (permissions.update) {
        this.actions.push({
          name: 'update_coupon',
          title: '<i class="fa fa-pencil" title="Update Coupon"></i>'
        })
      }
      if (permissions.delete) {
        this.actions.push({
          name: 'delete_coupon',
          title: '<i class="fa fa-trash" title="Detele Coupon"></i>'
        })
      }
    })
  }
  ngAfterViewInit() {
    this.smartTable.custom.subscribe((dataObject: any) => {
      switch (dataObject.action) {
        case "update_coupon":
          this.store.dispatch(startUpdateCoupon({ couponId: (<Coupon>dataObject.data).id }))
          break;
        case 'delete_coupon':
          this.store.dispatch(startDeleteCoupon({ couponId: (<Coupon>dataObject.data).id }))
          break;
      }
    })
  }
  /**
   * Getter $user
   * @return { Observable<User> }
   */
  public get $user(): Observable<User> {
    return this.store.select(getCurrentUser);
  }
  /**
     * Getter $coupons
     * @return { Observable<Coupon[]> }
     */
  public get $coupons(): Observable<Coupon[]> {
    return this.store.select(getAllCoupons);
  }
}
