import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { MarketsRoutingModule } from './markets-routing.module';
import { CreateMarketComponent } from './create-market/create-market.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateMarketComponent } from './update-market/update-market.component';
import { StoreModule } from '@ngrx/store';
import { MarketEffects } from './store/market.effects';
import { marketReducer } from './store/market.reducers';
import { EffectsModule } from '@ngrx/effects';
import { ListMarketComponent } from './list-market/list-market.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ListMarketComponent, CreateMarketComponent, UpdateMarketComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    MarketsRoutingModule,
    NgxFileDropModule,
    SharedModule,
    StoreModule.forFeature('markets', marketReducer),
    EffectsModule.forFeature([MarketEffects])
  ]
})
export class MarketsModule { }
