import { userActionTypes, usersLoaded, updateUser, deleteUser, updateUserSuccess, createUserSuccess } from './user.actions';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entities/user';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, setConfirmDeleteModalState, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { from, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class UserEffects {

  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActionTypes.loadUsers),
      concatMap(() => this.userService.all((AuthService.market?.name != 'Medina Market' ? 'store_id=' + AuthService.market?.id + '&' : '') + "with=role,creator,editor,store&sort=-created_at")),
      map((users: User[]) => userActionTypes.usersLoaded({ users })),
      tap(() => { this.store.dispatch(setLoadingSpinner({ status: false })) }),
    )
  });

  createUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActionTypes.createUser),
      concatMap((action) => this.userService.add(action.user)),
      map((user: User) => this.store.dispatch(createUserSuccess({ user }))),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'User Creation failed'
          }))
        return of();
      })
    )


  }, { dispatch: false }
  );

  deleteUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActionTypes.deleteUser),
      exhaustMap(
        (action) => {
          return this.userService.delete(action.userId).pipe(
            tap(() => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(displaySuccess({ message: 'User Deleted Successfully!', title: '' }));
            }),
            catchError((errResp: any) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              if (errResp != null)
                this.store.dispatch(displayError({
                  message: JSON.stringify(errResp.error.message), title: 'User Delete failed'
                }))
              return of();
            })
          );
        })

    )
  }, { dispatch: false });

  updateStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActionTypes.startUpdateUser),
      tap(() => this.router.navigateByUrl('/users/update-user'))
    )
  }, { dispatch: false });

  deleteStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActionTypes.startDeleteUser),
      exhaustMap((action) => this.runDialog(ConfirmDeleteComponent, action.userId)))
  }, { dispatch: false });

  runDialog = (content, userId) => {
    const modalRef = this.modalService.open(content, { centered: true });
    modalRef.componentInstance.onConfirm = () => {
      this.store.dispatch(deleteUser({ userId }))
    };
    return from(modalRef.result);
  };
  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActionTypes.updateUser),
      concatMap((action) => this.userService.update(<number>action.update.id, action.update.changes)),
      map((user: User) => this.store.dispatch(updateUserSuccess({ user: { id: user.id, changes: user } }))),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'User Update failed'
          }))
        return of();
      })
    )

  }, { dispatch: false });
  createUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActionTypes.createUserSuccess),
      tap(() => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.store.dispatch(displaySuccess({ message: 'User Created Successfully!', title: '' }))
        this.router.navigateByUrl('/users/list-user');
      })
    )
  }, { dispatch: false })
  updateUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActionTypes.updateUserSuccess),
      tap(() => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.store.dispatch(displaySuccess({ message: 'User Updated Successfully!', title: '' }))
        this.router.navigateByUrl('/users/list-user');
      })
    )
  }, { dispatch: false })
  constructor(private userService: UserService, private actions$: Actions, private router: Router, private store: Store<AppState>, private modalService: NgbModal, private authService: AuthService) { }
}