import { categoryActionTypes, deleteCategory, updateCategorySuccess, createCategorySuccess } from './category.actions';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/entities/category';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, setConfirmDeleteModalState, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { from, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { getAllCategories } from './category.selectors';
import { fips } from 'crypto';

@Injectable()
export class CategoryEffects {

  loadCategories$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActionTypes.loadCategories),
      concatMap(() => this.categoryService.all("with=creator,editor,parent,subcategories")),
      map((categories: Category[]) => categoryActionTypes.categoriesLoaded({ categories })),
      tap(() => { this.store.dispatch(setLoadingSpinner({ status: false })) }),
    )
  });

  createCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActionTypes.createCategory),
      concatMap((action) => this.categoryService.add(action.category)),
      map((category: Category) => {
        this.store.dispatch(createCategorySuccess({ category }))
      }),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Category Creation failed'
          }))
        return of();
      })
    )


  }, { dispatch: false }
  );

  deleteCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActionTypes.deleteCategory),
      exhaustMap(
        (action) => {
          return this.categoryService.delete(action.categoryId).pipe(
            tap(() => {

              this.store.dispatch(setLoadingSpinner({ status: false }));

              this.store.dispatch(displaySuccess({ message: 'Category Deleted Successfully!', title: '' }));
            }),
            catchError((errResp: any) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              if (errResp != null)
                this.store.dispatch(displayError({
                  message: JSON.stringify(errResp.error.message), title: 'Category Delete failed'
                }))
              return of();
            })
          );
        })
    )
  }, { dispatch: false });

  updateStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActionTypes.startUpdateCategory),
      tap(() => this.router.navigateByUrl('/categories/update-category'))
    )
  }, { dispatch: false });

  deleteStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActionTypes.startDeleteCategory),
      exhaustMap((action) => this.runDialog(ConfirmDeleteComponent, action.categoryId)))
  }, { dispatch: false });

  runDialog = (content, categoryId) => {
    const modalRef = this.modalService.open(content, { centered: true });
    modalRef.componentInstance.onConfirm = () => {
      this.store.dispatch(deleteCategory({ categoryId }))
    };
    return from(modalRef.result);
  };
  updateCategory$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActionTypes.updateCategory),
      concatMap((action) => this.categoryService.update(<number>action.update.id, action.update.changes)),
      map((category: Category) => this.store.dispatch(updateCategorySuccess({ category: { id: category.id, changes: category } }))),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Category Update failed'
          }))
        return of();
      })
    )

  }, { dispatch: false });
  createCategorySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActionTypes.createCategorySuccess),
      tap(() => {
        this.store.dispatch(displaySuccess({ message: 'Category Created Successfully!', title: '' }))
        this.store.dispatch(setLoadingSpinner({ status: false }));
      })
    )
  }, { dispatch: false })
  updateCategorySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(categoryActionTypes.updateCategorySuccess),
      tap(() => {
        this.store.dispatch(displaySuccess({ message: 'Category Updated Successfully!', title: '' }))
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.router.navigateByUrl('/categories/list-category');
      })
    )
  }, { dispatch: false })
  constructor(private categoryService: CategoryService, private actions$: Actions, private router: Router, private store: Store<AppState>, private modalService: NgbModal) { }
}