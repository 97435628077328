import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { Observable } from 'rxjs';
import { changeCategory, removeCategory, storeCategory } from './components/categories/store/category.actions';
import { getAllCategories } from './components/categories/store/category.selectors';
import { AppState } from './store/reducers';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  subscribe() {
    let echo = new Echo({
      broadcaster: 'pusher',
      key: '7aa177cd47bea729be33',
      cluster: 'eu'
    });

    echo.listen('backoffice', 'BroadcastData', (e) => {
      console.log('object:', e.object)
      switch (e.object) {
        case 'item_categories':
          console.log('action:', e.action)
          switch (e.action) {
            case 'store':
              console.log('changes:', e.changes)
              this.store.dispatch(storeCategory({ category: e.changes }));
              break;
            case 'update':
              console.log('changes:', e.changes)
              // this.store.select(getAllCategories).subscribe(())
              this.store.dispatch(changeCategory({ category: { id: e.changes.id, changes: e.changes } }));
              break;
            case 'destroy':
              console.log('changes:', e.changes)
              this.store.dispatch(removeCategory({ categoryId: e.changes.id }));
              break;
          }
          break;
      }
    });
  }
  showLoading: Observable<boolean>;
  errorMessage: Observable<string>;
  constructor(private store: Store<AppState>) {
  }

  ngOnInit() {
    Pusher.logToConsole = false;
    this.subscribe();
  }

}
