import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { User } from 'src/app/entities/user';


export const loadUsers = createAction(
  '[Users List] Load Users via Service',
);

export const usersLoaded = createAction(
  '[Users Effect] Users Loaded Successfully',
  props<{ users: User[] }>()
);

export const createUser = createAction(
  '[Create User Component] Create User',
  props<{ user: User }>()
);

export const createUserSuccess = createAction(
  '[create User Success] create User Success',
  props<{ user: User }>()
);
export const updateUserSuccess = createAction(
  '[update User Success] update User Success',
  props<{ user: Update<User> }>()
);
export const deleteUserSuccess = createAction(
  '[delete User Success] delete User Success',
  props<{ user: User }>()
);
export const deleteUser = createAction(
  '[Users List Operations] Delete User',
  props<{ userId: number }>()
);

export const startDeleteUser = createAction(
  '[Users List Operations] Start Delete User',
  props<{ userId: number }>()
);

export const updateUser = createAction(
  '[Users List Operations] Update User',
  props<{ update: Update<User> }>()
);
export const startUpdateUser = createAction(
  '[Update User Operations] Start Update',
  props<{ userId: number }>()
);


export const userActionTypes = {
  loadUsers,
  usersLoaded,
  createUser,
  startDeleteUser,
  deleteUser,
  updateUser,
  startUpdateUser,
  deleteUserSuccess,
  createUserSuccess,
  updateUserSuccess
};