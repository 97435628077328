<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h5>Categories Tree</h5>
    </div>
    <div class="card-body">
      <ul class="tree">
        <li *ngFor="let category of categories">
          <span>{{ category.name }}</span>
          <ul>
            <li *ngFor="let sub of category.subcategories">
              <span>{{ sub.name }}</span>
            </li>
            <li>
              <form class="add-button">
                <input
                  type="text"
                  name="name"
                  [id]="'category_'+category.id"
                  placeholder="New Category"
                />
                <button type="button" (click)="addSub(category.id)">Add Category</button>
              </form>
            </li>
          </ul>
        </li>
        <li>
          <form class="add-button" [formGroup]="categoryForm">
            <input
              type="text"
              name="name"
              formControlName="name"
              placeholder="New Category"
            />
            <button type="button" (click)="add()">Add Category</button>
          </form>
        </li>
      </ul>
    </div>
  </div>
</div>
