import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Market } from 'src/app/entities/market';


export const loadMarkets = createAction(
  '[Markets List] Load Markets via Service',
);

export const marketsLoaded = createAction(
  '[Markets Effect] Markets Loaded Successfully',
  props<{ markets: Market[] }>()
);

export const createMarket = createAction(
  '[Create Market Component] Create Market',
  props<{ market: Market }>()
);

export const createMarketSuccess = createAction(
  '[create Market Success] create Market Success',
  props<{ market: Market }>()
);
export const storeMarket = createAction(
  '[create Market Success] store Market Success',
  props<{ market: Market }>()
);
export const updateMarketSuccess = createAction(
  '[update Market Success] update Market Success',
  props<{ market: Update<Market> }>()
);
export const changeMarket = createAction(
  '[update Market Success] change Market Success',
  props<{ market: Update<Market> }>()
);
export const deleteMarketSuccess = createAction(
  '[delete Market Success] delete Market Success',
  props<{ market: Market }>()
);
export const deleteMarket = createAction(
  '[Markets List Operations] Delete Market',
  props<{ marketId: number }>()
);
export const removeMarket = createAction(
  '[Markets List Operations] Remove Market',
  props<{ marketId: number }>()
);

export const startDeleteMarket = createAction(
  '[Markets List Operations] Start Delete Market',
  props<{ marketId: number }>()
);

export const updateMarket = createAction(
  '[Markets List Operations] Update Market',
  props<{ update: Update<Market> }>()
);
export const startUpdateMarket = createAction(
  '[Update Market Operations] Start Update',
  props<{ marketId: number }>()
);


export const marketActionTypes = {
  loadMarkets,
  marketsLoaded,
  createMarket,
  startDeleteMarket,
  deleteMarket,
  updateMarket,
  startUpdateMarket,
  deleteMarketSuccess,
  createMarketSuccess,
  updateMarketSuccess,
  storeMarket,
  changeMarket,
  removeMarket
};