import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Market } from 'src/app/entities/market';
import { marketActionTypes } from './market.actions';

export interface MarketState extends EntityState<Market> {
  marketsLoaded: boolean;
  selectedMarketId: number;
}

export const adapter: EntityAdapter<Market> = createEntityAdapter<Market>({
  sortComparer: (a: Market, b: Market) => b.updated_at.localeCompare(<string>a.updated_at),
});

export const initialState = adapter.getInitialState({
  marketsLoaded: false,
  selectedMarketId: null
});

export const marketReducer = createReducer(
  initialState,

  on(marketActionTypes.marketsLoaded, (state, action) => {
    return adapter.setAll(
      action.markets,
      { ...state, marketsLoaded: true }
    );
  }),

  on(marketActionTypes.createMarketSuccess, (state, action) => {
    console.log(action)
    return adapter.addOne(action.market, state);
  }),
  on(marketActionTypes.storeMarket, (state, action) => {
    console.log(action)
    return adapter.addOne(action.market, state);
  }),

  on(marketActionTypes.deleteMarket, (state, action) => {
    return adapter.removeOne(action.marketId, state);
  }),
  on(marketActionTypes.removeMarket, (state, action) => {
    return adapter.removeOne(action.marketId, state);
  }),
  on(marketActionTypes.updateMarketSuccess, (state, action) => {
    return adapter.updateOne(action.market, { ...state, selectedMarketId: null });
  }),
  on(marketActionTypes.changeMarket, (state, action) => {
    return adapter.updateOne(action.market, state);
  }),
  on(marketActionTypes.startUpdateMarket, (state, action) => {
    return { ...state, selectedMarketId: action.marketId }
  }),
  on(marketActionTypes.startDeleteMarket, (state, action) => {
    return { ...state, selectedMarketId: action.marketId }
  }),
);

export const { selectAll } = adapter.getSelectors();

export const selectedMarketId = (state) => state.selectedMarketId;
