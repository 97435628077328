import { createAction, props } from '@ngrx/store';
export const SET_LOADING_ACTION = '[Shared State] Set loading spinner';
export const SET_ERROR_MESSAGE = '[Shared State] Set Error Message';
export const SET_SUCCESS_MESSAGE = '[Shared State] Set Success Message';
export const SET_WARNING_MESSAGE = '[Shared State] Set Warning Message';
export const SET_INFO_MESSAGE = '[Shared State] Set Info Message';
export const SET_CONFIRM_DELETE_MODAL_STATE = '[Shared State] Set Confirm Delete Modal State';

export const setLoadingSpinner = createAction(
  SET_LOADING_ACTION,
  props<{ status: boolean }>()
);

export const displayError = createAction(
  SET_ERROR_MESSAGE,
  props<{ message: string, title: string | null }>()
);

export const displaySuccess = createAction(
  SET_SUCCESS_MESSAGE,
  props<{ message: string, title: string | null }>()
);

export const displayWarning = createAction(
  SET_WARNING_MESSAGE,
  props<{ message: string, title: string | null }>()
);
export const displayInfo = createAction(
  SET_INFO_MESSAGE,
  props<{ message: string, title: string | null }>()
);

export const setConfirmDeleteModalState = createAction(
  SET_CONFIRM_DELETE_MODAL_STATE,
  props<{ onConfirm: Function }>()
);

