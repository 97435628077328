import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Coupon } from 'src/app/entities/coupon';
import { couponActionTypes } from './coupon.actions';

export interface CouponState extends EntityState<Coupon> {
  couponsLoaded: boolean;
  selectedCouponId: number;
}

export const adapter: EntityAdapter<Coupon> = createEntityAdapter<Coupon>({
  sortComparer: (a: Coupon, b: Coupon) => b.updated_at.localeCompare(<string>a.updated_at),
});

export const initialState = adapter.getInitialState({
  couponsLoaded: false,
  selectedCouponId: null
});

export const couponReducer = createReducer(
  initialState,

  on(couponActionTypes.couponsLoaded, (state, action) => {
    return adapter.setAll(
      action.coupons,
      { ...state, couponsLoaded: true }
    );
  }),

  on(couponActionTypes.createCouponSuccess, (state, action) => {
    console.log(action)
    return adapter.addOne(action.coupon, state);
  }),
  on(couponActionTypes.storeCoupon, (state, action) => {
    console.log(action)
    return adapter.addOne(action.coupon, state);
  }),

  on(couponActionTypes.deleteCoupon, (state, action) => {
    return adapter.removeOne(action.couponId, state);
  }),
  on(couponActionTypes.removeCoupon, (state, action) => {
    return adapter.removeOne(action.couponId, state);
  }),
  on(couponActionTypes.updateCouponSuccess, (state, action) => {
    return adapter.updateOne(action.coupon, { ...state, selectedCouponId: null });
  }),
  on(couponActionTypes.changeCoupon, (state, action) => {
    return adapter.updateOne(action.coupon, state);
  }),
  on(couponActionTypes.startUpdateCoupon, (state, action) => {
    return { ...state, selectedCouponId: action.couponId }
  }),
  on(couponActionTypes.startDeleteCoupon, (state, action) => {
    return { ...state, selectedCouponId: action.couponId }
  }),
);

export const { selectAll } = adapter.getSelectors();

export const selectedCouponId = (state) => state.selectedCouponId;
