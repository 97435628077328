import { CategoryState, selectAll, selectedCategoryId } from './category.reducers';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { Category } from 'src/app/entities/category';

export const categoryFeatureSelector = createFeatureSelector<CategoryState>('categories');

export const getAllCategories = createSelector(
  categoryFeatureSelector,
  selectAll
);
export const getSelectedCategoryId = createSelector(
  categoryFeatureSelector,
  state => state.selectedCategoryId
);

export const getSelectedCategory = createSelector(
  categoryFeatureSelector,
  state => state.selectedCategoryId != null ? state.entities[state.selectedCategoryId] : null
);

export const areCategoriesLoaded = createSelector(
  categoryFeatureSelector,
  state => state.categoriesLoaded
);