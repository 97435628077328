import { UserState, selectAll, selectedUserId } from './user.reducers';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export const userFeatureSelector = createFeatureSelector<UserState>('users');

export const getAllUsers = createSelector(
  userFeatureSelector,
  selectAll
);
export const getSelectedUserId = createSelector(
  userFeatureSelector,
  state => state.selectedUserId
);

export const getSelectedUser = createSelector(
  userFeatureSelector,
  state => state.selectedUserId != null ? state.entities[state.selectedUserId] : null
);

export const areUsersLoaded = createSelector(
  userFeatureSelector,
  state => state.usersLoaded
);