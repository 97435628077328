import { roleActionTypes, rolesLoaded, updateRole, deleteRole, updateRoleSuccess, createRoleSuccess } from './role.actions';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { Role } from 'src/app/entities/role';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, setConfirmDeleteModalState, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { from, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';

@Injectable()
export class RoleEffects {

  loadRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleActionTypes.loadRoles),
      concatMap(() => this.roleService.all("with=permissions,creator,editor&sort=-created_at")),
      map((roles: Role[]) => roleActionTypes.rolesLoaded({ roles })),
      tap(() => { this.store.dispatch(setLoadingSpinner({ status: false })) }),
    )
  });

  createRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleActionTypes.createRole),
      concatMap((action) => this.roleService.add(action.role)),
      map((role: Role) => this.store.dispatch(createRoleSuccess({ role }))),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Role Creation failed'
          }))
        return of();
      })
    )


  }, { dispatch: false }
  );

  deleteRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleActionTypes.deleteRole),
      exhaustMap(
        (action) => {
          return this.roleService.delete(action.roleId).pipe(
            tap(() => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(displaySuccess({ message: 'Role Deleted Successfully!', title: '' }));
            }),
            catchError((errResp: any) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              if (errResp != null)
                this.store.dispatch(displayError({
                  message: JSON.stringify(errResp.error.message), title: 'Role Delete failed'
                }))
              return of();
            })
          );
        })

    )
  }, { dispatch: false });

  updateStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleActionTypes.startUpdateRole),
      tap(() => this.router.navigateByUrl('/roles/update-role'))
    )
  }, { dispatch: false });

  deleteStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleActionTypes.startDeleteRole),
      exhaustMap((action) => this.runDialog(ConfirmDeleteComponent, action.roleId)))
  }, { dispatch: false });

  runDialog = (content, roleId) => {
    const modalRef = this.modalService.open(content, { centered: true });
    modalRef.componentInstance.onConfirm = () => {
      this.store.dispatch(deleteRole({ roleId }))
    };
    return from(modalRef.result);
  };
  updateRole$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleActionTypes.updateRole),
      concatMap((action) => this.roleService.update(<number>action.update.id, action.update.changes)),
      map((role: Role) => this.store.dispatch(updateRoleSuccess({ role: { id: role.id, changes: role } }))),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Role Update failed'
          }))
        return of();
      })
    )

  }, { dispatch: false });
  createRoleSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleActionTypes.createRoleSuccess),
      tap(() => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.store.dispatch(displaySuccess({ message: 'Role Created Successfully!', title: '' }))
        this.router.navigateByUrl('/roles/list-role');
      })
    )
  }, { dispatch: false })
  updateRoleSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(roleActionTypes.updateRoleSuccess),
      tap(() => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.store.dispatch(displaySuccess({ message: 'Role Updated Successfully!', title: '' }))
        this.router.navigateByUrl('/roles/list-role');
      })
    )
  }, { dispatch: false })
  constructor(private roleService: RoleService, private actions$: Actions, private router: Router, private store: Store<AppState>, private modalService: NgbModal) { }
}