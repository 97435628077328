
import { exhaustMap, map, catchError, tap, mergeMap } from 'rxjs/operators';
import {
  autoLogin,
  autoLogout,
  checkIdentity,
  loginFail,
  loginStart,
  loginSuccess,
} from './auth.actions';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { displayError, displaySuccess, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { User } from 'src/app/entities/user';
import { AppState } from 'src/app/store/reducers';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router
  ) { }

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginStart),
      exhaustMap((action) => {
        if (!action.validEmail || !action.validPassword) {
          this.store.dispatch(setLoadingSpinner({ status: false }));
          if (!action.validPassword) {
            this.store.dispatch(displayError({ message: 'You must enter a valid password !', title: 'Invalid password' }))
          }
          if (!action.validEmail) {
            this.store.dispatch(displayError({ message: 'You must enter a valid E-mail ! (ex: example@email.xyz)', title: 'Invalid email' }))
          }
          return of(loginFail({ error: null }));
        }
        else {
          return this.authService.login(action.body).pipe(
            map((data) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              const user: User = data;
              this.authService.setUserInLocalStorage(user);
              return loginSuccess({ user, redirect: true });
            }),
            catchError((errResp) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              return of(loginFail({ error: errResp }));
            })
          );
        }
      })
    );
  });

  checkIdentity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkIdentity),
      exhaustMap(
        () => {
          const user = this.authService.getUserFromLocalStorage();
          if (user != null)
            return this.authService.checkIdentity().pipe(
              map((data) => {
                this.store.dispatch(setLoadingSpinner({ status: false }));
                const user: User = data;
                user.token = this.authService.getUserFromLocalStorage().token;
                this.authService.setUserInLocalStorage(user);
                AuthService.market = user.store;
                AuthService.perms = user.role.permissions;
                AuthService.user = user;
                return loginSuccess({ user, redirect: false });
              }),
              catchError((errResp) => {
                this.store.dispatch(setLoadingSpinner({ status: false }));
                return of(loginFail({ error: null }))
              })
            );
          this.store.dispatch(setLoadingSpinner({ status: false }));
          return of(loginFail({ error: null }))
        })
    );
  });
  loginRedirect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(loginSuccess),
        tap((action) => {
          this.store.dispatch(displaySuccess({ message: 'Welcome ' + action.user.extra.full_name, title: '' }))
          if (action.redirect) {
            this.router.navigate(['/dashboard/default']);
          }
        })
      );
    },
    { dispatch: false }
  );
  loginFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...[loginFail]),
        tap((action) => {
          if (action.error != null)
            this.store.dispatch(displayError({
              message: action.error.error.hasOwnProperty('email') ?
                JSON.stringify(action.error.error.email[0])
                : action.error.error.hasOwnProperty('password') ?
                  JSON.stringify(action.error.error.password[0])
                  : JSON.stringify(action.error.error.message), title: ''
            }))

          localStorage.clear();
          this.router.navigate(['/auth/login']);
        })
      );
    },
    { dispatch: false }
  );
  autoLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(autoLogin),
      mergeMap((action) => {
        const user = this.authService.getUserFromLocalStorage();
        if (user == null)
          return of(loginFail({ error: null }))
        return of(loginSuccess({ user, redirect: true }));
      })
    );
  });

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(autoLogout),
        map((action) => {

          this.store.dispatch(setLoadingSpinner({ status: false }));
          this.authService.logout();
          localStorage.clear();
          this.router.navigate(['/']);
        })
      );
    },
    { dispatch: false }
  );
}
