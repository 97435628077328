import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { User } from 'src/app/entities/user';
import { AppState } from 'src/app/store/reducers';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { createCoupon } from '../store/coupon.actions';

@Component({
  selector: 'app-create-coupon',
  templateUrl: './create-coupon.component.html',
  styleUrls: ['./create-coupon.component.scss']
})
export class CreateCouponComponent implements OnInit {
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public generalForm: FormGroup = new FormGroup(
    {
      title: new FormControl('', Validators.required),
      code: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      free_shipping: new FormControl('', Validators.required),
      discount: new FormControl('', Validators.required),
      discount_type: new FormControl('', Validators.required),
      enabled: new FormControl('', Validators.required)
    }
  );
  public usageForm: FormGroup = new FormGroup(
    {
      usage_per_limit: new FormControl('', Validators.required),
      usage_per_customer: new FormControl('', Validators.required)
    }
  );
  constructor(private toastr: ToastrService, private store: Store<AppState>, private calendar: NgbCalendar) { }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngOnInit() {
    this.$user.subscribe(user => {
      console.log(user)
      if (!(user.role.permissions.find(permission => permission.name == 'create_coupon').is_permitted)) {
        window.history.back();
      }
    })
  }
  add() {
    if (this.generalForm.invalid || this.usageForm.invalid) {
      if (this.generalForm.controls.title.invalid) {
        this.toastr.error('The coupon title field is required !', 'coupon title is required');
      }
      if (this.generalForm.controls.code.invalid) {
        this.toastr.error('The coupon code field is required !', 'coupon code is required');
      }
      if (this.generalForm.controls.start_date.invalid) {
        this.toastr.error('The coupon start date field is required !', 'coupon start date is required');
      }
      if (this.generalForm.controls.end_date.invalid) {
        this.toastr.error('The coupon end date field is required !', 'coupon end date is required');
      }
      if (this.generalForm.controls.discount.invalid) {
        this.toastr.error('The coupon discount field is required !', 'coupon discount is required');
      }
      if (this.generalForm.controls.discount_type.invalid) {
        this.toastr.error('The coupon discount type field is required !', 'coupon discount type is required');
      }
      if (this.usageForm.controls.usage_per_limit.invalid) {
        this.toastr.error('The coupon usage per limit field is required !', 'coupon usage per limit is required');
      }
      if (this.usageForm.controls.usage_per_customer.invalid) {
        this.toastr.error('The coupon usage per customer field is required !', 'coupon usage per customer is required');
      }
    } else {

      let coupon: any = {
        title: this.generalForm.controls.title.value,
        code: this.generalForm.controls.code.value,
        start_date: this.generalForm.controls.start_date.value.year + '-' + this.generalForm.controls.start_date.value.month + '-' + this.generalForm.controls.start_date.value.day,
        end_date: this.generalForm.controls.end_date.value.year + '-' + this.generalForm.controls.end_date.value.month + '-' + this.generalForm.controls.end_date.value.day,
        free_shipping: this.generalForm.controls.free_shipping.value,
        discount: this.generalForm.controls.discount.value,
        discount_type: this.generalForm.controls.discount_type.value,
        enabled: this.generalForm.controls.enabled.value,
        usage_per_limit: this.usageForm.controls.usage_per_limit.value,
        usage_per_customer: this.usageForm.controls.usage_per_customer.value
      };
      this.store.dispatch(setLoadingSpinner({ status: true }))
      this.store.dispatch(createCoupon({ coupon }))
    }
  }
  /**
   * Getter $user
   * @return { Observable<User> }
   */
  public get $user(): Observable<User> {
    return this.store.select(getCurrentUser);
  }
}
