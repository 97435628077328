import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../entities/user';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(private http: HttpService) { }

  all(query: string = null): Observable<User[]> {
    return <Observable<User[]>>(this.http.get("users?" + query))
  }

  add(data: any) {
    return this.http.post("users", data)
  }

  me() {
    return this.http.get("user")
  }

  update(id: number, object: object) {
    return this.http.put('users/' + id, object)
  }

  delete(id: number) {
    return this.http.delete('users/' + id)
  }

  get(id: number) {
    return this.http.get('users/' + id)
  }
}
