import { OrderState, selectAll, selectedOrderId } from './order.reducers';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export const orderFeatureSelector = createFeatureSelector<OrderState>('orders');

export const getAllOrders = createSelector(
  orderFeatureSelector,
  selectAll
);
export const getSelectedOrderId = createSelector(
  orderFeatureSelector,
  state => state.selectedOrderId
);

export const getSelectedOrder = createSelector(
  orderFeatureSelector,
  state => state.selectedOrderId != null ? state.entities[state.selectedOrderId] : null
);

export const areOrdersLoaded = createSelector(
  orderFeatureSelector,
  state => state.ordersLoaded
);