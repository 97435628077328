import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../entities/category';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpService) { }

  all(query: string = null): Observable<Category[]> {
    return <Observable<Category[]>>(this.http.get("item-categories?" + query))
  }

  add(data: any) {
    return this.http.post("item-categories", data)
  }

  update(id: number, object: object) {
    return this.http.put('item-categories/' + id, object)
  }

  delete(id: number) {
    return this.http.delete('item-categories/' + id)
  }

  get(id: number) {
    return this.http.get('item-categories/' + id)
  }
}
