import { areUsersLoaded } from './store/user.selectors';
import { loadUsers, usersLoaded } from './store/user.actions';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';

@Injectable()
export class UsersResolver implements Resolve<Observable<any>> {

  constructor(private store: Store<AppState>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store
      .pipe(
        select(areUsersLoaded),
        tap((usersLoaded) => {
          if (!usersLoaded) {
            this.store.dispatch(setLoadingSpinner({ status: true }))
            this.store.dispatch(loadUsers());
          }
        }),
        filter(usersLoaded => usersLoaded),
        first()
      );
  }
}
