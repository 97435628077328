import { setConfirmDeleteModalState, setLoadingSpinner } from './shared.actions';
import { createReducer, on } from '@ngrx/store';
import { initialState } from './shared.state';
import { displaySuccess, displayWarning, displayError, displayInfo } from "./shared.actions";


const _sharedReducer = createReducer(
  initialState,
  on(setLoadingSpinner, (state, action) => {
    return {
      ...state,
      isLoading: action.status,
    };
  }),
  on(setConfirmDeleteModalState, (state, action) => {
    return {
      ...state,
      confirmDelete: action.onConfirm,
    };
  }),
  on(displaySuccess, state => state),
  on(displayError, state => state),
  on(displayWarning, state => state),
  on(displayInfo, state => state),
);

export function SharedReducer(state, action) {
  return _sharedReducer(state, action);
}
