import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { Observable } from 'rxjs';
import { Category } from 'src/app/entities/category';
import { User } from 'src/app/entities/user';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AppState } from 'src/app/store/reducers';
import { displayError } from 'src/app/store/Shared/shared.actions';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { startDeleteCategory, startUpdateCategory } from '../store/category.actions';
import { getAllCategories } from '../store/category.selectors';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-list-category',
  templateUrl: './list-category.component.html',
  styleUrls: ['./list-category.component.scss']
})
export class ListCategoryComponent implements OnInit {
  public category_list = []
  categories: Category[] = []
  @ViewChild('table')
  smartTable: Ng2SmartTableComponent;

  constructor(
    private store: Store<AppState>,
    private attachmentService: AttachmentService
  ) {
  }

  public actions = []

  public settings = {
    actions: {
      position: 'left',
      custom: this.actions,
      edit: false,
      delete: false,
      add: false
    },
    columns: {
      photo: {
        title: 'Picture',
        type: 'html',
        valuePrepareFunction: (photo) => {
          return photo == null || photo == '' ? '' : '<img src="' + this.src(photo) + '"height=100"></img>';
        },
      },
      parent: {
        title: 'Parent Category',
        valuePrepareFunction: (parent) => {
          return parent?.name;
        },
        filterFunction(parent?: Category, search?: string): boolean {
          return parent?.name?.toLowerCase()?.indexOf(search?.toLowerCase()) > -1 || search === '';
        },
        compareFunction: (direction: any, a: Category, b: Category): number => {
          return a != null ? b != null ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 * direction : a.name.toLowerCase() > b.name.toLowerCase() ? direction : 0 : direction : -1 * direction;
        }
      },
      name: {
        title: 'Name'
      },
      creator: {
        title: 'Created by',
        valuePrepareFunction: (creator) => {
          return creator?.extra?.full_name;
        },
        filterFunction(creator?: User, search?: string): boolean {
          return creator?.extra?.full_name?.toLowerCase()?.indexOf(search?.toLowerCase()) > -1 || search === '';
        },
        compareFunction: (direction: any, a: User, b: User): number => {
          return a != null ? b != null ? a.extra.full_name.toLowerCase() < b.extra.full_name.toLowerCase() ? -1 * direction : a.extra.full_name.toLowerCase() > b.extra.full_name.toLowerCase() ? direction : 0 : direction : -1 * direction;
        }
      },
      editor: {
        title: 'Updated by',
        valuePrepareFunction: (editor) => {
          return editor?.extra?.full_name;
        },
        filterFunction(creator?: User, search?: string): boolean {
          return creator?.extra?.full_name?.toLowerCase()?.indexOf(search?.toLowerCase()) > -1 || search === '';
        },
        compareFunction: (direction: any, a: User, b: User): number => {
          return a != null ? b != null ? a.extra.full_name.toLowerCase() < b.extra.full_name.toLowerCase() ? -1 * direction : a.extra.full_name.toLowerCase() > b.extra.full_name.toLowerCase() ? direction : 0 : direction : -1 * direction;
        }
      },
      created_at: {
        title: 'Created at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        },
        filterFunction(date?: string, search?: string): boolean {
          return new Date(date).toLocaleString().indexOf(search?.toLowerCase()) > -1 || search === '';
        },
      },
      updated_at: {
        title: 'Updated at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        },
        filterFunction(date?: string, search?: string): boolean {
          return new Date(date).toLocaleString().indexOf(search?.toLowerCase()) > -1 || search === '';
        }
      },
    },
  };
  src(name) {
    return environment.baseUrl + "attachments/download/" + name
  }
  ngOnInit() {
    this.$user.subscribe(user => {
      let permissions = {
        create: user.role.permissions.find(permission => permission.name == 'create_category').is_permitted,
        update: user.role.permissions.find(permission => permission.name == 'update_category').is_permitted,
        delete: user.role.permissions.find(permission => permission.name == 'delete_category').is_permitted,
        showAll: user.role.permissions.find(permission => permission.name == 'show_all_category').is_permitted,
      };
      if (!permissions.showAll) {
        window.history.back();
      }
      if (permissions.update) {
        this.actions.push({
          name: 'update_category',
          title: '<i class="fa fa-pencil" title="Update Category"></i>'
        })
      }
      if (permissions.delete) {
        this.actions.push({
          name: 'delete_category',
          title: '<i class="fa fa-trash" title="Detele Categorys"></i>'
        })
      }
    })
  }

  ngAfterViewInit() {
    this.smartTable.custom.subscribe((dataObject: any) => {
      switch (dataObject.action) {
        case "update_category":
          this.store.dispatch(startUpdateCategory({ categoryId: (<Category>dataObject.data).id }))
          break;
        case 'delete_category':
          this.store.dispatch(startDeleteCategory({ categoryId: (<Category>dataObject.data).id }))
          break;
      }
    })
  }
  /**
   * Getter $categories
   * @return { Observable<Category[]> }
   */
  public get $categories(): Observable<Category[]> {
    return this.store.select(getAllCategories);
  }
  /**
   * Getter $user
   * @return { Observable<User> }
   */
  public get $user(): Observable<User> {
    return this.store.select(getCurrentUser);
  }

}

