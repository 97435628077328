import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { AppState } from 'src/app/store/reducers';
import { displayError, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { autoLogin, loginStart } from '../store/auth.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    password: new FormControl('', Validators.required)
  });

  constructor(private store: Store<AppState>, private toastr: ToastrService) {
  }


  ngOnInit() {
    this.store.dispatch(autoLogin({ redirect: true }))
  }

  onSubmit() {
    this.store.dispatch(setLoadingSpinner({ status: true }));
    let errors = [];
    this.store.dispatch(loginStart({
      validEmail: this.loginForm.controls.email.valid,
      validPassword: this.loginForm.controls.password.valid,
      body: this.loginForm.valid ? { email: this.loginForm.value.email, password: this.loginForm.value.password } : null
    }));
  }
}

