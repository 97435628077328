import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'categories',
    loadChildren: () => import('../../components/categories/categories.module').then(m => m.CategoriesModule),
  },
  {
    path: 'messages',
    loadChildren: () => import('../../components/messages/messages.module').then(m => m.MessagesModule),
  },
  {
    path: 'stores',
    loadChildren: () => import('../../components/markets/markets.module').then(m => m.MarketsModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/items/items.module').then(m => m.ItemsModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Products"
    }
  },
  {
    path: 'sales',
    loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
    data: {
      breadcrumb: "Sales"
    }
  },
  {
    path: 'coupons',
    loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
    data: {
      breadcrumb: "Coupons"
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: "Users"
    }
  },
  {
    path: 'roles',
    loadChildren: () => import('../../components/roles/roles.module').then(m => m.RolesModule),
    data: {
      breadcrumb: "Roles"
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  }
];