import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateItemComponent } from './create-item/create-item.component';
import { UpdateItemComponent } from './update-item/update-item.component';
import { RolesResolver } from '../roles/roles.resolver';
import { ListItemComponent } from './list-item/list-item.component';
import { CategoriesResolver } from '../categories/categories.resolver';
import { ItemsResolver } from './items.resolver';
import { MarketsResolver } from '../markets/markets.resolver';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-product',
        component: ListItemComponent,
        resolve: {
          items: ItemsResolver
        },
        data: {
          title: "Product List",
          breadcrumb: "Product List"
        }
      },
      {
        path: 'create-product',
        component: CreateItemComponent,
        resolve: {
          categories: CategoriesResolver,
          markets: MarketsResolver
        },
        data: {
          title: "Create Product",
          breadcrumb: "Create Product"
        }
      },
      {
        path: 'update-product',
        component: UpdateItemComponent,
        resolve: {
          categories: CategoriesResolver,
          markets: MarketsResolver
        },
        data: {
          title: "Create Product",
          breadcrumb: "Create Product"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [ItemsResolver, CategoriesResolver, MarketsResolver]
})
export class ItemsRoutingModule { }
