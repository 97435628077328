import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { Role } from 'src/app/entities/role';
import { RolePermission } from 'src/app/entities/rolePermission';
import { User } from 'src/app/entities/user';
import { RoleService } from 'src/app/services/role.service';
import { AppState } from 'src/app/store/reducers';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { createRole } from '../store/role.actions';
import { getAllRoles } from '../store/role.selectors';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {
  private permissions: String[] = [
    'User',
    'Role',
    'Category',
    'Product',
    'Store',
    'Coupon',
    'Order'
  ];
  roleForm = new FormGroup({
    name: new FormControl('', Validators.required),
    is_administrator: new FormControl({ defaultValue: false }, Validators.required)
  });
  permissionForm = new FormGroup({});

  constructor(private store: Store<AppState>, private toastr: ToastrService) {
  }


  ngOnInit() {
    this.permissions.forEach((permission: String) => {
      this.roleForm.controls.is_administrator.patchValue(false);
      this.permissionForm.addControl('create_' + permission.toLowerCase(), new FormControl({ defaultValue: false }, Validators.required));
      this.permissionForm.get('create_' + permission.toLowerCase()).patchValue(false);
      this.permissionForm.addControl('update_' + permission.toLowerCase(), new FormControl({ defaultValue: false }, Validators.required));
      this.permissionForm.get('update_' + permission.toLowerCase()).patchValue(false);
      this.permissionForm.addControl('delete_' + permission.toLowerCase(), new FormControl({ defaultValue: false }, Validators.required));
      this.permissionForm.get('delete_' + permission.toLowerCase()).patchValue(false);
      this.permissionForm.addControl('show_all_' + permission.toLowerCase(), new FormControl({ defaultValue: false }, Validators.required));
      this.permissionForm.get('show_all_' + permission.toLowerCase()).patchValue(false);
    });
    console.log(this.permissionForm);
  }
  getControllerName(index): String {
    return Object.keys(this.permissionForm.controls)[index];
  }

  createRole(){
    // this.ngxService.start();
    if (this.roleForm.invalid || this.permissionForm.invalid) {
      // this.ngxService.stop();
      if (this.roleForm.controls.name.invalid) {
        this.toastr.error('The name field is required !', 'Name is required');
      }
      if (this.roleForm.controls.is_administrator.invalid) {
        this.toastr.error('You should inform either this role is an administrator or not !', 'Is Administrator is required');
      }
      if (this.permissionForm.invalid) {
        this.toastr.error('All permissions are required !', 'Permissions are required');
      }
    }
    else {
      this.store.dispatch(setLoadingSpinner({ status: true }));
      let role: Role = { id: null, name: this.roleForm.controls.name.value, is_administrator: this.roleForm.controls.is_administrator.value, permissions: [] };
      Object.keys(this.permissionForm.controls).forEach((key, index) => {
        role.permissions.push({ id: null, name: key, is_permitted: Object.values(this.permissionForm.controls)[index].value });
      });
      console.log(role);
      // this.roleService.add(role).subscribe((res: any) => {
      //   this.toastr.success('Your new role have been added successfully.', 'Role Created Successfully');
      //   this.router.navigateByUrl('roles/list-role')
      // }, (error) => {
      //   error.error.hasOwnProperty('name') ?
      //     this.toastr.error(JSON.stringify(error.error.name[0]))
      //     : error.error.hasOwnProperty('is_administrator') ?
      //       this.toastr.error(JSON.stringify(error.error.is_administrator[0]))
      //       : error.error.hasOwnProperty('permissions') ?
      //         this.toastr.error(JSON.stringify(error.error.permissions[0]))
      //         : this.toastr.error(JSON.stringify(error.error.message));
      // }, () => {

      //   this.ngxService.stop();
      // })
      this.store.dispatch(createRole({ role }));

    }
    // this.ngxService.stop();
  }

  /**
     * Getter $permissions
     * @return { String[] }
     */
  public get $permissions(): String[] {
    return this.permissions;
  }
  /**
   * Getter $roles
   * @return { Observable<Role[]> }
   */
  public get $roles(): Observable<Role[]> {
    return this.store.select(getAllRoles);
  }
}
