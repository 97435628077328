import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Message } from 'src/app/entities/message';


export const loadMessages = createAction(
  '[Messages List] Load Messages via Service',
);

export const messagesLoaded = createAction(
  '[Messages Effect] Messages Loaded Successfully',
  props<{ messages: Message[] }>()
);

export const storeMessage = createAction(
  '[create Message Success] store Message Success',
  props<{ message: Message }>()
);
export const updateMessageSuccess = createAction(
  '[update Message Success] update Message Success',
  props<{ message: Update<Message> }>()
);
export const changeMessage = createAction(
  '[update Message Success] change Message Success',
  props<{ message: Update<Message> }>()
);
export const deleteMessageSuccess = createAction(
  '[delete Message Success] delete Message Success',
  props<{ message: Message }>()
);
export const deleteMessage = createAction(
  '[Messages List Operations] Delete Message',
  props<{ messageId: number }>()
);
export const removeMessage = createAction(
  '[Messages List Operations] Remove Message',
  props<{ messageId: number }>()
);

export const startDeleteMessage = createAction(
  '[Messages List Operations] Start Delete Message',
  props<{ messageId: number }>()
);

export const updateMessage = createAction(
  '[Messages List Operations] Update Message',
  props<{ update: Update<Message> }>()
);
export const startUpdateMessage = createAction(
  '[Update Message Operations] Start Update',
  props<{ messageId: number }>()
);


export const messageActionTypes = {
  loadMessages,
  messagesLoaded,
  startDeleteMessage,
  deleteMessage,
  updateMessage,
  startUpdateMessage,
  deleteMessageSuccess,
  updateMessageSuccess,
  storeMessage,
  changeMessage,
  removeMessage
};