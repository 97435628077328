import { CouponState, selectAll, selectedCouponId } from './coupon.reducers';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export const couponFeatureSelector = createFeatureSelector<CouponState>('coupons');

export const getAllCoupons = createSelector(
  couponFeatureSelector,
  selectAll
);
export const getSelectedCouponId = createSelector(
  couponFeatureSelector,
  state => state.selectedCouponId
);

export const getSelectedCoupon = createSelector(
  couponFeatureSelector,
  state => state.selectedCouponId != null ? state.entities[state.selectedCouponId] : null
);

export const areCouponsLoaded = createSelector(
  couponFeatureSelector,
  state => state.couponsLoaded
);