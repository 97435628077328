import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/entities/user';
import { userActionTypes, usersLoaded } from './user.actions';
import { AuthService } from 'src/app/services/auth.service';

export interface UserState extends EntityState<User> {
  usersLoaded: boolean;
  selectedUserId: number;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  sortComparer: (a: User, b: User) => b.updated_at.localeCompare(<string>a.updated_at),
});

export const initialState = adapter.getInitialState({
  usersLoaded: false,
  selectedUserId: null
});

export const userReducer = createReducer(
  initialState,

  on(userActionTypes.usersLoaded, (state, action) => {
    return adapter.setAll(
      action.users.filter(user => user.id != AuthService.user?.id),
      { ...state, usersLoaded: true }
    );
  }),

  on(userActionTypes.createUserSuccess, (state, action) => {
    return adapter.addOne(action.user, state);
  }),

  on(userActionTypes.deleteUser, (state, action) => {
    return adapter.removeOne(action.userId, state);
  }),

  on(userActionTypes.updateUserSuccess, (state, action) => {
    console.log(action);
    return adapter.updateOne(action.user, { ...state, selectedUserId: null });
  }),
  on(userActionTypes.startUpdateUser, (state, action) => {
    return { ...state, selectedUserId: action.userId }
  }),
  on(userActionTypes.startDeleteUser, (state, action) => {
    return { ...state, selectedUserId: action.userId }
  }),

);

export const { selectAll } = adapter.getSelectors();

export const selectedUserId = (state) => state.selectedUserId;
