import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Stores', icon: 'shopping-bag', type: 'sub', active: false, children: [
				{ path: '/stores/list-store', title: 'Store List', type: 'link' },
				{ path: '/stores/create-store', title: 'Create Store', type: 'link' },
			]
		},
		{
			title: 'Roles', icon: 'lock', type: 'sub', active: false, children: [
				{ path: '/roles/list-role', title: 'Role List', type: 'link' },
				{ path: '/roles/create-role', title: 'Create Role', type: 'link' },
			]
		},
		{
			title: 'Users', icon: 'users', type: 'sub', active: false, children: [
				{ path: '/users/list-user', title: 'User List', type: 'link' },
				{ path: '/users/create-user', title: 'Create User', type: 'link' },
			]
		},
		{
			title: 'Categories', icon: 'list', type: 'sub', active: false, children: [
				{ path: '/categories/category-tree', title: 'Categories Tree', type: 'link' },
				{ path: '/categories/list-category', title: 'Category List', type: 'link' },
				{ path: '/categories/create-category', title: 'Create Category', type: 'link' },
			]
		},
		{
			title: 'Products', icon: 'box', type: 'sub', active: false, children: [
				{ path: '/products/list-product', title: 'Product List', type: 'link' },
				{ path: '/products/create-product', title: 'Create Product', type: 'link' },
				// {
				// 	title: 'Physical', type: 'sub', children: [
				// 		{ path: '/products/physical/category', title: 'Category', type: 'link' },
				// 		{ path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
				// 		{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
				// 		{ path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
				// 		{ path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
				// 	]
				// },
				// {
				// 	title: 'digital', type: 'sub', children: [
				// 		{ path: '/products/digital/digital-category', title: 'Category', type: 'link' },
				// 		{ path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
				// 		{ path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
				// 		{ path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
				// 	]
				// },
			]
		},
		{
			title: 'Coupons', icon: 'tag', type: 'sub', active: false, children: [
				{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
				{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
			]
		},
		{
			title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
				{ path: '/sales/orders', title: 'Orders', type: 'link' },
				{ path: '/sales/transactions', title: 'Transactions', type: 'link' },
			]
		},
		// {
		// 	title: 'Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
		// },
		{
			path: '/messages/list-message', title: 'Messages', icon: 'message-circle', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Logout',path: '/auth/login', icon: 'log-in', type: 'link', active: false
		}
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
