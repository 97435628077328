import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Order } from 'src/app/entities/order';
import { orderActionTypes } from './order.actions';

export interface OrderState extends EntityState<Order> {
  ordersLoaded: boolean;
  selectedOrderId: number;
}

export const adapter: EntityAdapter<Order> = createEntityAdapter<Order>({
  sortComparer: (a: Order, b: Order) => b.updated_at.localeCompare(<string>a.updated_at),
});

export const initialState = adapter.getInitialState({
  ordersLoaded: false,
  selectedOrderId: null
});

export const orderReducer = createReducer(
  initialState,

  on(orderActionTypes.ordersLoaded, (state, action) => {
    return adapter.setAll(
      action.orders,
      { ...state, ordersLoaded: true }
    );
  }),

  on(orderActionTypes.createOrderSuccess, (state, action) => {
    console.log(action)
    return adapter.addOne(action.order, state);
  }),
  on(orderActionTypes.storeOrder, (state, action) => {
    console.log(action)
    return adapter.addOne(action.order, state);
  }),
  on(orderActionTypes.updateOrderSuccess, (state, action) => {
    return adapter.updateOne(action.order, { ...state, selectedOrderId: null });
  }),
  on(orderActionTypes.changeOrder, (state, action) => {
    return adapter.updateOne(action.order, state);
  }),
  on(orderActionTypes.startUpdateOrder, (state, action) => {
    return { ...state, selectedOrderId: action.orderId }
  }),
);

export const { selectAll } = adapter.getSelectors();

export const selectedOrderId = (state) => state.selectedOrderId;
