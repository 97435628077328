<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Category Details</h5>
        </div>
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-secondary" [routerLink]="'/categories/create-category'">Create Category</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable category-image">
                <div class="table-responsive">
                    <ng2-smart-table #table [settings]="settings" [source]="$categories | async"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>