import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Item } from 'src/app/entities/item';
import { itemActionTypes, itemsLoaded } from './item.actions';

export interface ItemState extends EntityState<Item> {
  itemsLoaded: boolean;
  selectedItemId: number;
}

export const adapter: EntityAdapter<Item> = createEntityAdapter<Item>({
  sortComparer: (a: Item, b: Item) => b.updated_at.localeCompare(<string>a.updated_at),
});

export const initialState = adapter.getInitialState({
  itemsLoaded: false,
  selectedItemId: null
});

export const itemReducer = createReducer(
  initialState,

  on(itemActionTypes.itemsLoaded, (state, action) => {
    return adapter.setAll(
      action.items,
      { ...state, itemsLoaded: true }
    );
  }),

  on(itemActionTypes.createItemSuccess, (state, action) => {
    return adapter.addOne(action.item, state);
  }),

  on(itemActionTypes.deleteItem, (state, action) => {
    return adapter.removeOne(action.itemId, state);
  }),

  on(itemActionTypes.updateItemSuccess, (state, action) => {
    console.log(action);
    return adapter.updateOne(action.item, { ...state, selectedItemId: null });
  }),
  on(itemActionTypes.startUpdateItem, (state, action) => {
    return { ...state, selectedItemId: action.itemId }
  }),
  on(itemActionTypes.startDeleteItem, (state, action) => {
    return { ...state, selectedItemId: action.itemId }
  }),

);

export const { selectAll } = adapter.getSelectors();

export const selectedItemId = (state) => state.selectedItemId;
