import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Message } from 'src/app/entities/message';
import { messageActionTypes, messagesLoaded } from './message.actions';

export interface MessageState extends EntityState<Message> {
  messagesLoaded: boolean;
  selectedMessageId: number;
}

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>({
  sortComparer: (a: Message, b: Message) => b.updated_at.localeCompare(<string>a.updated_at),
});

export const initialState = adapter.getInitialState({
  messagesLoaded: false,
  selectedMessageId: null
});

export const messageReducer = createReducer(
  initialState,

  on(messageActionTypes.messagesLoaded, (state, action) => {
    return adapter.setAll(
      action.messages,
      { ...state, messagesLoaded: true }
    );
  }),
  on(messageActionTypes.storeMessage, (state, action) => {
    console.log(action)
    return adapter.addOne(action.message, state);
  }),

  on(messageActionTypes.deleteMessage, (state, action) => {
    return adapter.removeOne(action.messageId, state);
  }),
  on(messageActionTypes.removeMessage, (state, action) => {
    return adapter.removeOne(action.messageId, state);
  }),
  on(messageActionTypes.updateMessageSuccess, (state, action) => {
    return adapter.updateOne(action.message, { ...state });
  }),
  on(messageActionTypes.changeMessage, (state, action) => {
    return adapter.updateOne(action.message, state);
  }),
  on(messageActionTypes.startUpdateMessage, (state, action) => {
    return { ...state, selectedMessageId: action.messageId }
  }),
  on(messageActionTypes.startDeleteMessage, (state, action) => {
    return { ...state, selectedMessageId: action.messageId }
  }),
);

export const { selectAll } = adapter.getSelectors();

export const selectedMessageId = (state) => state.selectedMessageId;
