import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { ItemsRoutingModule } from './items-routing.module';
import { CreateItemComponent } from './create-item/create-item.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateItemComponent } from './update-item/update-item.component';
import { StoreModule } from '@ngrx/store';
import { ItemEffects } from './store/item.effects';
import { itemReducer } from './store/item.reducers';
import { EffectsModule } from '@ngrx/effects';
import { RoleEffects } from '../roles/store/role.effects';
import { ListItemComponent } from './list-item/list-item.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ListItemComponent, CreateItemComponent, UpdateItemComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    ItemsRoutingModule,
    SharedModule,
    StoreModule.forFeature('items', itemReducer),
    EffectsModule.forFeature([ItemEffects, RoleEffects])
  ]
})
export class ItemsModule { }
