import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { CouponsRoutingModule } from './coupons-routing.module';
import { ListCouponComponent } from './list-coupon/list-coupon.component';
import { CreateCouponComponent } from './create-coupon/create-coupon.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'src/app/shared/shared.module';
import { couponReducer } from './store/coupon.reducers';
import { CouponEffects } from './store/coupon.effects';
import { Ng2SmartTableModule } from 'ng2-smart-table';

@NgModule({
  declarations: [ListCouponComponent, CreateCouponComponent],
  imports: [
    CommonModule,
    CouponsRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    SharedModule,
    StoreModule.forFeature('coupons', couponReducer),
    EffectsModule.forFeature([CouponEffects])
  ]
})
export class CouponsModule { }
