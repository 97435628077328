<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Add User</h5>
                </div>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Role">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="role" role="tabpanel"
                                    aria-labelledby="role-tab">
                                    <form [formGroup]="roleForm" class="needs-validation user-add" novalida>
                                        <h4>Role Details</h4>
                                        <div class="form-group row">
                                            <label for="name" class="col-xl-3 col-md-4"><span>*</span>
                                                Role Name</label>
                                            <input class="form-control col-xl-8 col-md-7" formControlName="name"
                                                id="name" type="text" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="name" class="col-xl-3 col-md-4"><span>*</span>
                                                Is Administrator ?</label>
                                            <div class="col-xl-9 col-sm-8">
                                                <div
                                                    class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated pb-0">
                                                    <label class="d-block mb-0" for="is_administrator_yes">
                                                        <input class="radio_animated" id="is_administrator_yes"
                                                            name="is_administrator" [value]="true" type="radio"
                                                            formControlName="is_administrator" />
                                                        Yes
                                                    </label>
                                                    <label class="d-block mb-0" for="is_administrator_no">
                                                        <input class="radio_animated" id="is_administrator_no"
                                                            type="radio" [value]="false" name="is_administrator"
                                                            formControlName="is_administrator" />
                                                        No
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                        <ngb-tab>
                            <ng-template ngbTabTitle>Permission
                                <ng-template ngbTabContent>
                                    <form [formGroup]="permissionForm" class="needs-validation user-add" novalidate="">
                                        <div class="permission-block"
                                            *ngFor="let permission of this.$permissions; let index = index;">
                                            <div class="attribute-blocks">
                                                <h5 class="f-w-600 mb-3">{{ permission }} Related permissions </h5>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Create {{ permission }}</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block"
                                                                for="{{getControllerName(index * 4 + 0)}}_allow">
                                                                <input class="radio_animated"
                                                                    id="{{getControllerName(index * 4 + 0)}}_allow"
                                                                    type="radio"
                                                                    [formControlName]="getControllerName(index * 4 + 0)"
                                                                    name="{{getControllerName(index * 4 + 0)}}"
                                                                    [value]="true"> Allow </label>
                                                            <label class="d-block"
                                                                for="{{getControllerName(index * 4 + 0)}}_deny">
                                                                <input class="radio_animated"
                                                                    id="{{getControllerName(index * 4 + 0)}}_deny"
                                                                    type="radio"
                                                                    [formControlName]="getControllerName(index * 4 + 0)"
                                                                    name="{{getControllerName(index * 4 + 0)}}"
                                                                    [value]="false">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Update {{ permission }}</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block"
                                                                for="{{getControllerName(index * 4 + 1)}}_allow">
                                                                <input class="radio_animated"
                                                                    id="{{getControllerName(index * 4 + 1)}}_allow"
                                                                    type="radio"
                                                                    [formControlName]="getControllerName(index * 4 + 1)"
                                                                    name="{{getControllerName(index * 4 + 1)}}"
                                                                    [value]="true">
                                                                Allow
                                                            </label>
                                                            <label class="d-block"
                                                                for="{{getControllerName(index * 4 + 1)}}_deny">
                                                                <input class="radio_animated"
                                                                    id="{{getControllerName(index * 4 + 1)}}_deny"
                                                                    type="radio"
                                                                    [formControlName]="getControllerName(index * 4 + 1)"
                                                                    name="{{getControllerName(index * 4 + 1)}}"
                                                                    [value]="false">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Delete {{ permission }}</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block"
                                                                for="{{getControllerName(index * 4 + 2)}}_allow">
                                                                <input class="radio_animated"
                                                                    id="{{getControllerName(index * 4 + 2)}}_allow"
                                                                    type="radio"
                                                                    [formControlName]="getControllerName(index * 4 + 2)"
                                                                    name="{{getControllerName(index * 4 + 2)}}"
                                                                    [value]="true"> Allow </label>
                                                            <label class="d-block"
                                                                for="{{getControllerName(index * 4 + 2)}}_deny">
                                                                <input class="radio_animated"
                                                                    id="{{getControllerName(index * 4 + 2)}}_deny"
                                                                    type="radio"
                                                                    [formControlName]="getControllerName(index * 4 + 2)"
                                                                    name="{{getControllerName(index * 4 + 2)}}"
                                                                    [value]="false">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Show All {{ permission }}</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block"
                                                                for="{{getControllerName(index * 4 + 3)}}_allow">
                                                                <input class="radio_animated"
                                                                    id="{{getControllerName(index * 4 + 3)}}_allow"
                                                                    type="radio"
                                                                    [formControlName]="getControllerName(index * 4 + 3)"
                                                                    name="{{getControllerName(index * 4 + 3)}}"
                                                                    [value]="true"> Allow </label>
                                                            <label class="d-block"
                                                                for="{{getControllerName(index * 4 + 3)}}_deny">
                                                                <input class="radio_animated"
                                                                    id="{{getControllerName(index * 4 + 3)}}_deny"
                                                                    type="radio"
                                                                    [formControlName]="getControllerName(index * 4 + 3)"
                                                                    name="{{getControllerName(index * 4 + 3)}}"
                                                                    [value]="false">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </ng-template>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                    <div class="pull-right">
                        <button type="button" (click)="update()" class="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->