import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Market } from 'src/app/entities/market';
import { Role } from 'src/app/entities/role';
import { User } from 'src/app/entities/user';
import { AppState } from 'src/app/store/reducers';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { getAllMarkets } from '../../markets/store/market.selectors';
import { getAllRoles } from '../../roles/store/role.selectors';
import { updateUser } from '../store/user.actions';
import { getSelectedUser } from '../store/user.selectors';

export class CustomValidators {
  static MatchValidator(source: string, target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sourceCtrl = control.get(source);
      const targetCtrl = control.get(target);

      return sourceCtrl && targetCtrl && sourceCtrl.value !== targetCtrl.value
        ? { mismatch: true }
        : null;
    };
  }
}
@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit {
  public accountForm: FormGroup = new FormGroup(
    {
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      birthdate: new FormControl(''),
      address: new FormControl(''),
      gender: new FormControl('', Validators.required),
      phone: new FormControl('', Validators.required),
      // confirm_password: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      market: new FormControl('', Validators.required)
    },
    [CustomValidators.MatchValidator('password', 'confirm_password')]
  );

  selected: User;
  roles: Role[] = [];
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router
  ) { }


  ngOnInit() {
    this.store.dispatch(setLoadingSpinner({ status: true }))
    this.store.select(getSelectedUser).subscribe((user: User) => {
      this.store.dispatch(setLoadingSpinner({ status: false }))
      if (user == null)
        this.router.navigateByUrl('/users/list-user');
      else {
        this.selected = user;
        this.accountForm.patchValue({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          role: user.role.id,
          market: user.store.id,
          phone: user.phone,
          birthdate: user.birth_date,
          gender: user.gender,
          address: user.address,
        })
      }
    })

  }

  update() {
    if (this.accountForm.invalid) {
      if (this.accountForm.controls.role.invalid) {
        this.toastr.error('The role field is required', 'Invalid role');
      }
      if (this.accountForm.controls.market.invalid) {
        this.toastr.error('The store field is required', 'Invalid store');
      } if (this.accountForm.controls.gender.invalid) {
        this.toastr.error('The gender field is required', 'Invalid gender');
      }
      if (this.accountForm.controls.phone.invalid) {
        this.toastr.error('The phone field is required', 'Invalid phone');
      }
      if (this.accountForm.controls.email.invalid) {
        this.toastr.error('You must enter a valid E-mail ! (ex: example@email.xyz)', 'Invalid email');
      }
      if (this.accountForm.controls.last_name.invalid) {
        this.toastr.error('The last name field is required !', 'Last name is required');
      }
      if (this.accountForm.controls.first_name.invalid) {
        this.toastr.error('The first name field is required !', 'First name is required');
      }
    } else {
      let user: any = {
        id: this.selected.id,
        role_id: this.accountForm.controls.role.value,
        store_id: this.accountForm.controls.market.value,
        first_name: this.accountForm.controls.first_name.value,
        last_name: this.accountForm.controls.last_name.value,
        birth_date: this.accountForm.controls.birthdate.value,
        address: this.accountForm.controls.address.value,
        gender: this.accountForm.controls.gender.value,
        phone: this.accountForm.controls.phone.value,
        email: this.accountForm.controls.email.value
      };
      this.store.dispatch(setLoadingSpinner({ status: true }))
      this.store.dispatch(updateUser({ update: { id: this.selected.id, changes: user } }));

    }
  }
  /**
   * Getter $selected
   * @return { Observable<User> }
   */
  public get $selected(): Observable<User> {
    return this.store.select(getSelectedUser);
  }
  /**
   * Getter $roles
   * @return { Observable<Role[]> }
   */
  public get $roles(): Observable<Role[]> {
    return this.store.select(getAllRoles);
  }
  /**
   * Getter $markets
   * @return { Observable<Market[]> }
   */
  public get $markets(): Observable<Market[]> {
    return this.store.select(getAllMarkets);
  }
}
