import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { Observable } from 'rxjs';
import { Role } from 'src/app/entities/role';
import { AppState } from 'src/app/store/reducers';
import { startDeleteRole, startUpdateRole } from '../store/role.actions';
import { getAllRoles } from '../store/role.selectors';

@Component({
  selector: 'app-list-role',
  templateUrl: './list-role.component.html',
  styleUrls: ['./list-role.component.scss']
})
export class ListRoleComponent implements OnInit {
  public role_list = []
  roles: Role[] = []
  @ViewChild('table')
  smartTable: Ng2SmartTableComponent;

  constructor(
    private store: Store<AppState>
  ) {
  }

  public settings = {
    actions: {
      position: 'left',
      custom: [
        {
          name: 'update_role',
          title: '<i class="fa fa-pencil" title="Update Categorys"></i>'
        },
        {
          name: 'delete_role',
          title: '<i class="fa fa-trash" title="Detele Categorys"></i>'
        }
      ],
      edit: false,
      delete: false,
      add: false
    },
    columns: {
      name: {
        title: 'Role Name',
      },

      is_administrator: {
        title: 'Is Administrator?',
        type: 'html',

        valuePrepareFunction: (value) => {
          return value ? '<div class="badge badge-pill badge-success">Yes</div>' : '<div class="badge badge-pill badge-warning">No</div>';
        },

        filter: false,
      },
      creator: {
        title: 'Created by',
        valuePrepareFunction: (creator) => {
          return creator?.extra?.full_name;
        }
      },
      editor: {
        title: 'Updated by',
        valuePrepareFunction: (editor) => {
          return editor?.extra?.full_name;
        }
      },
      created_at: {
        title: 'Created at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        }
      },
      updated_at: {
        title: 'Updated at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        }
      },
    },
  };

  ngOnInit() {
    this.$roles.subscribe((data) => console.log(data));

  }

  ngAfterViewInit() {
    this.smartTable.custom.subscribe((dataObject: any) => {
      switch (dataObject.action) {
        case "update_role":
          this.store.dispatch(startUpdateRole({ roleId: (<Role>dataObject.data).id }))
          break;
        case 'delete_role':
          this.store.dispatch(startDeleteRole({ roleId: (<Role>dataObject.data).id }))
          break;
      }
    })

  }
  /**
   * Getter $roles
   * @return { Observable<Role[]> }
   */
  public get $roles(): Observable<Role[]> {
    return this.store.select(getAllRoles);
  }

}

