import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Item } from 'src/app/entities/item';


export const loadItems = createAction(
  '[Items List] Load Items via Service',
);

export const itemsLoaded = createAction(
  '[Items Effect] Items Loaded Successfully',
  props<{ items: Item[] }>()
);

export const createItem = createAction(
  '[Create Item Component] Create Item',
  props<{ item: Item }>()
);

export const createItemSuccess = createAction(
  '[create Item Success] create Item Success',
  props<{ item: Item }>()
);
export const updateItemSuccess = createAction(
  '[update Item Success] update Item Success',
  props<{ item: Update<Item> }>()
);
export const deleteItemSuccess = createAction(
  '[delete Item Success] delete Item Success',
  props<{ item: Item }>()
);
export const deleteItem = createAction(
  '[Items List Operations] Delete Item',
  props<{ itemId: number }>()
);

export const startDeleteItem = createAction(
  '[Items List Operations] Start Delete Item',
  props<{ itemId: number }>()
);

export const updateItem = createAction(
  '[Items List Operations] Update Item',
  props<{ update: Update<Item> }>()
);
export const startUpdateItem = createAction(
  '[Update Item Operations] Start Update',
  props<{ itemId: number }>()
);


export const itemActionTypes = {
  loadItems,
  itemsLoaded,
  createItem,
  startDeleteItem,
  deleteItem,
  updateItem,
  startUpdateItem,
  deleteItemSuccess,
  createItemSuccess,
  updateItemSuccess
};