<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card tab2-card">
        <div class="card-header">
          <h5>Message From {{ selected.full_name }}</h5>
        </div>
        <div class="card-body tab2-card">
          <ngb-tabset class="tab-coupon">
            <ngb-tab title="Message">
              <ng-template ngbTabContent>
                <div
                  class="tab-pane fade active show"
                  id="message"
                  role="tabpanel"
                  aria-labelledby="message-tab"
                >
                <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">{{ selected.full_name }}</h5>
                      <h6 class="card-subtitle mb-2 text-muted">{{ selected.email }}</h6>
                      <h6 class="card-subtitle mb-2 text-muted">{{ selected.phone }}</h6>
                      <hr>
                      <h6 class="card-subtitle mb-2 text-muted">{{ selected.object }}</h6>
                      <p class="card-text">{{ selected.message }}</p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
          
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
