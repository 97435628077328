import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Message } from '../entities/message';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpService) { }

  all(query: string = null): Observable<Message[]> {
    return <Observable<Message[]>>(this.http.get("messages?" + query))
  }

  update(id: number, object: object) {
    return this.http.put('messages/' + id, object)
  }

  delete(id: number) {
    return this.http.delete('messages/' + id)
  }

  get(id: number) {
    return this.http.get('messages/' + id)
  }
}
