import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { UpdateCategoryComponent } from './update-category/update-category.component';
import { CategoriesResolver } from './categories.resolver';
import { RolesResolver } from '../roles/roles.resolver';
import { ListCategoryComponent } from './list-category/list-category.component';
import { CategoryTreeComponent } from './category-tree/category-tree.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'category-tree',
        component: CategoryTreeComponent,
        resolve: {
          categories: CategoriesResolver
        },
        data: {
          title: "Categories Tree",
          breadcrumb: "Categories Tree"
        }
      },
      {
        path: 'list-category',
        component: ListCategoryComponent,
        resolve: {
          categories: CategoriesResolver
        },
        data: {
          title: "Category List",
          breadcrumb: "Category List"
        }
      },
      {
        path: 'create-category',
        component: CreateCategoryComponent,
        resolve: {
          categories: CategoriesResolver
        },
        data: {
          title: "Create Category",
          breadcrumb: "Create Category"
        }
      },
      {
        path: 'update-category',
        component: UpdateCategoryComponent,
        resolve: {
          categories: CategoriesResolver
        },
        data: {
          title: "Create Category",
          breadcrumb: "Create Category"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [CategoriesResolver, RolesResolver]
})
export class CategoriesRoutingModule { }
