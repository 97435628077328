import { couponActionTypes, deleteCoupon, updateCouponSuccess, createCouponSuccess } from './coupon.actions';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CouponService } from 'src/app/services/coupon.service';
import { Coupon } from 'src/app/entities/coupon';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { from, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';


@Injectable()
export class CouponEffects {

  loadCoupons$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(couponActionTypes.loadCoupons),
      concatMap(() => this.couponService.all("with=creator,editor")),
      map((coupons: Coupon[]) => couponActionTypes.couponsLoaded({ coupons })),
      tap(() => { this.store.dispatch(setLoadingSpinner({ status: false })) }),
    )
  });   

  createCoupon$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(couponActionTypes.createCoupon),
      concatMap((action) => this.couponService.add(action.coupon)),
      map((coupon: Coupon) => {
        this.store.dispatch(createCouponSuccess({ coupon }))
      }),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Coupon Creation failed'
          }))
        return of();
      })
    )


  }, { dispatch: false }
  );

  deleteCoupon$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(couponActionTypes.deleteCoupon),
      exhaustMap(
        (action) => {
          return this.couponService.delete(action.couponId).pipe(
            tap(() => {

              this.store.dispatch(setLoadingSpinner({ status: false }));

              this.store.dispatch(displaySuccess({ message: 'Coupon Deleted Successfully!', title: '' }));
            }),
            catchError((errResp: any) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              if (errResp != null)
                this.store.dispatch(displayError({
                  message: JSON.stringify(errResp.error.message), title: 'Coupon Delete failed'
                }))
              return of();
            })
          );
        })
    )
  }, { dispatch: false });

  updateStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(couponActionTypes.startUpdateCoupon),
      tap(() => this.router.navigateByUrl('/coupons/update-coupons'))
    )
  }, { dispatch: false });

  deleteStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(couponActionTypes.startDeleteCoupon),
      exhaustMap((action) => this.runDialog(ConfirmDeleteComponent, action.couponId)))
  }, { dispatch: false });

  runDialog = (content, couponId) => {
    const modalRef = this.modalService.open(content, { centered: true });
    modalRef.componentInstance.onConfirm = () => {
      this.store.dispatch(deleteCoupon({ couponId }))
    };
    return from(modalRef.result);
  };
  updateCoupon$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(couponActionTypes.updateCoupon),
      concatMap((action) => this.couponService.update(<number>action.update.id, action.update.changes)),
      map((coupon: Coupon) => this.store.dispatch(updateCouponSuccess({ coupon: { id: coupon.id, changes: coupon } }))),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Coupon Update failed'
          }))
        return of();
      })
    )

  }, { dispatch: false });
  createCouponSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(couponActionTypes.createCouponSuccess),
      tap(() => {
        this.store.dispatch(displaySuccess({ message: 'Coupon Created Successfully!', title: '' }))
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.router.navigateByUrl('/coupons/list-coupons');
      })
    )
  }, { dispatch: false })
  updateCouponSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(couponActionTypes.updateCouponSuccess),
      tap(() => {
        this.store.dispatch(displaySuccess({ message: 'Coupon Updated Successfully!', title: '' }))
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.router.navigateByUrl('/coupons/list-coupons');
      })
    )
  }, { dispatch: false })
  constructor(private couponService: CouponService, private actions$: Actions, private router: Router, private store: Store<AppState>, private modalService: NgbModal) { }
}