import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Role } from 'src/app/entities/role';


export const loadRoles = createAction(
  '[Roles List] Load Roles via Service',
);

export const rolesLoaded = createAction(
  '[Roles Effect] Roles Loaded Successfully',
  props<{ roles: Role[] }>()
);

export const createRole = createAction(
  '[Create Role Component] Create Role',
  props<{ role: Role }>()
);

export const createRoleSuccess = createAction(
  '[create Role Success] create Role Success',
  props<{ role: Role }>()
);
export const updateRoleSuccess = createAction(
  '[update Role Success] update Role Success',
  props<{ role: Update<Role> }>()
);
export const deleteRoleSuccess = createAction(
  '[delete Role Success] delete Role Success',
  props<{ role: Role }>()
);
export const deleteRole = createAction(
  '[Roles List Operations] Delete Role',
  props<{ roleId: number }>()
);

export const startDeleteRole = createAction(
  '[Roles List Operations] Start Delete Role',
  props<{ roleId: number }>()
);

export const updateRole = createAction(
  '[Roles List Operations] Update Role',
  props<{ update: Update<Role> }>()
);
export const startUpdateRole = createAction(
  '[Update Role Operations] Start Update',
  props<{ roleId: number }>()
);


export const roleActionTypes = {
  loadRoles,
  rolesLoaded,
  createRole,
  startDeleteRole,
  deleteRole,
  updateRole,
  startUpdateRole,
  deleteRoleSuccess,
  createRoleSuccess,
  updateRoleSuccess
};