import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Category } from 'src/app/entities/category';
import { User } from 'src/app/entities/user';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AppState } from 'src/app/store/reducers';
import { getAllCategories } from '../store/category.selectors';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { ToastrService } from 'ngx-toastr';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { createCategory } from '../store/category.actions';

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss']
})
export class CategoryTreeComponent implements OnInit {
  public category_list = []
  categories: Category[] = []
  public categoryForm: FormGroup = new FormGroup(
    {
      name: new FormControl('', Validators.required)
    }
  );
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService
  ) {
  }
  add() {
    if (this.categoryForm.invalid) {
      if (this.categoryForm.controls.name.invalid) {
        this.toastr.error('The category name field is required !', 'category name is required');
      }
    } else {

      let category: any = {
        name: this.categoryForm.controls.name.value,
        parent_id: null,
        photo: null
      };
      this.store.dispatch(setLoadingSpinner({ status: true }))
      this.store.dispatch(createCategory({ category }))
      this.categoryForm.patchValue({ name: '' })
    }
  }
  addSub(parent) {
    let sub = (<HTMLInputElement>document.querySelector('#category_' + parent));
    if (sub?.value == '') {
      this.toastr.error('The category name field is required !', 'category name is required');
    } else {
      let category: any = {
        name: sub.value,
        parent_id: parent,
        photo: null
      };
      this.store.dispatch(setLoadingSpinner({ status: true }))
      this.store.dispatch(createCategory({ category }))
    }
  }

  ngOnInit() {
    this.$user.subscribe(user => {
      let permissions = {
        create: user.role.permissions.find(permission => permission.name == 'create_category').is_permitted,
        update: user.role.permissions.find(permission => permission.name == 'update_category').is_permitted,
        delete: user.role.permissions.find(permission => permission.name == 'delete_category').is_permitted,
        showAll: user.role.permissions.find(permission => permission.name == 'show_all_category').is_permitted,
      };
      if (!permissions.showAll) {
        window.history.back();
      }
      else {
        this.$categories.subscribe(categories => {
          this.categories = JSON.parse(JSON.stringify(categories.filter(category => category.parent_id == null)));
          this.categories.map(category => category.subcategories = categories.filter(cat => cat.parent_id == category.id))
        })
      }
    })


  }

  ngAfterViewInit() {

  }
  /**
   * Getter $categories
   * @return { Observable<Category[]> }
   */
  public get $categories(): Observable<Category[]> {
    return this.store.select(getAllCategories);
  }
  /**
   * Getter $user
   * @return { Observable<User> }
   */
  public get $user(): Observable<User> {
    return this.store.select(getCurrentUser);
  }

}

