import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Category } from 'src/app/entities/category';


export const loadCategories = createAction(
  '[Categories List] Load Categories via Service',
);

export const categoriesLoaded = createAction(
  '[Categories Effect] Categories Loaded Successfully',
  props<{ categories: Category[] }>()
);

export const createCategory = createAction(
  '[Create Category Component] Create Category',
  props<{ category: Category }>()
);

export const createCategorySuccess = createAction(
  '[create Category Success] create Category Success',
  props<{ category: Category }>()
);
export const storeCategory = createAction(
  '[create Category Success] store Category Success',
  props<{ category: Category }>()
);
export const updateCategorySuccess = createAction(
  '[update Category Success] update Category Success',
  props<{ category: Update<Category> }>()
);
export const changeCategory = createAction(
  '[update Category Success] change Category Success',
  props<{ category: Update<Category> }>()
);
export const deleteCategorySuccess = createAction(
  '[delete Category Success] delete Category Success',
  props<{ category: Category }>()
);
export const deleteCategory = createAction(
  '[Categories List Operations] Delete Category',
  props<{ categoryId: number }>()
);
export const removeCategory = createAction(
  '[Categories List Operations] Remove Category',
  props<{ categoryId: number }>()
);

export const startDeleteCategory = createAction(
  '[Categories List Operations] Start Delete Category',
  props<{ categoryId: number }>()
);

export const updateCategory = createAction(
  '[Categories List Operations] Update Category',
  props<{ update: Update<Category> }>()
);
export const startUpdateCategory = createAction(
  '[Update Category Operations] Start Update',
  props<{ categoryId: number }>()
);


export const categoryActionTypes = {
  loadCategories,
  categoriesLoaded,
  createCategory,
  startDeleteCategory,
  deleteCategory,
  updateCategory,
  startUpdateCategory,
  deleteCategorySuccess,
  createCategorySuccess,
  updateCategorySuccess,
  storeCategory,
  changeCategory,
  removeCategory
};