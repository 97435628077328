import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersRoutingModule } from './users-routing.module';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateUserComponent } from './update-user/update-user.component';
import { StoreModule } from '@ngrx/store';
import { UserEffects } from './store/user.effects';
import { userReducer } from './store/user.reducers';
import { EffectsModule } from '@ngrx/effects';
import { RoleEffects } from '../roles/store/role.effects';

@NgModule({
  declarations: [ListUserComponent, CreateUserComponent, UpdateUserComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    StoreModule.forFeature('users', userReducer),
    EffectsModule.forFeature([UserEffects, RoleEffects])
  ]
})
export class UsersModule { }
