import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpService } from './http.service';
import { User } from '../entities/user';
import { Observable } from 'rxjs';
import { Market } from '../entities/market';
import { RolePermission } from '../entities/rolePermission';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public static market: Market;
  public static user: User;
  public static perms: RolePermission[] = [];
  constructor(private http: HttpService) {
  }

  login(data: any): Observable<User> {
    return <Observable<User>>this.http.post('login', data)
  }

  logout() {
    return this.http.get('logout')
  }
  register(object: object) {
    return this.http.post('register', object)
  }

  update(id: number, object: object) {
    return this.http.put('users/' + id, object)
  }

  passwordCreate(object: object) {
    return this.http.post('password/create', object)
  }

  passwordReset(object: object) {
    return this.http.post('password/reset', object)
  }

  checkIdentity(): Observable<User> {
    return <Observable<User>>this.http.get('user');
  }
  setUserInLocalStorage(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  getUserFromLocalStorage(): User {
    const userDataString = localStorage.getItem('user');
    if (userDataString) {
      return JSON.parse(userDataString);
    }
    return null;
  }
  getErrorMessage(message: any) {
    switch (message) {
      case 'EMAIL_NOT_FOUND':
        return 'Email Not Found';
      case 'INVALID_PASSWORD':
        return 'Invalid Password';
      case 'EMAIL_EXISTS':
        return 'Email already exists';
      default:
        return 'Unknown error occurred. Please try again';
    }
  }
  // /**
  //  * Getter $connectedUser
  //  * @return {User | null }
  //  */
  // public get $connectedUser(): User | null {
  //   return this.connectedUser;
  // }
  // /**
  //  * Setter $connectedUser
  //  * @param {User | null} value
  //  */
  // public set $connectedUser(value: User | null) {
  //   this.connectedUser = value;
  // }
}