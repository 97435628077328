import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/reducers';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { onConfirmDelete } from 'src/app/store/Shared/shared.selector';

@Component({
  selector: 'confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit {
  @Input() public onConfirm;
  constructor(private store: Store<AppState>, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onDelete = () => {
    this.store.dispatch(setLoadingSpinner({ status: true }))
    this.onConfirm();
    this.onCancel();
    // this.store.select(onConfirmDelete).subscribe(deleteFun => { deleteFun(); this.activeModal.close() });
  }
  onCancel = () => {
    this.activeModal.close();
  }


}
