import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { transactionsDB } from 'src/app/shared/tables/transactions';
import { AppState } from 'src/app/store/reducers';
import { startUpdateOrder } from '../store/order.actions';
import { Order } from 'src/app/entities/order';
import { Observable } from 'rxjs';
import { User } from 'src/app/entities/user';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { getAllOrders } from '../store/order.selectors';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  public transactions = []

  @ViewChild('table')
  smartTable: Ng2SmartTableComponent;

  constructor(private store: Store<AppState>) {
  }

  public settings = {
    actions: false,
    hideSubHeader: true,
    columns: {
      order_id: {
        title: 'Order Id', filter: false
      },
      transaction_id: {
        title: 'Transaction Id', filter: false
      },
      date: {
        title: 'Date', filter: false
      },
      pay_method: {
        title: 'Payment Method', filter: false,
        type: 'html',

        valuePrepareFunction: (value) => {
          return '<div class="badge badge-pill badge-info">' + value.toUpperCase() + '</div>';
        },
      },
      delivery_status: {
        title: 'Delivery Status', filter: false,
        type: 'html',

        valuePrepareFunction: (value) => {
          return value.includes('Paid') ? '<div class="badge badge-pill badge-success">' + value.toUpperCase() + '</div>' : '<div class="badge badge-pill badge-warning">' + value.toUpperCase() + '</div>';
        },
      },
      amount: {
        title: 'Amount', filter: false
      }
    },
  };
  ngOnInit() {
    this.$user.subscribe(user => {
      let permissions = {
        create: user.role.permissions.find(permission => permission.name == 'create_order').is_permitted,
        update: user.role.permissions.find(permission => permission.name == 'update_order').is_permitted,
        delete: user.role.permissions.find(permission => permission.name == 'delete_order').is_permitted,
        showAll: user.role.permissions.find(permission => permission.name == 'show_all_order').is_permitted,
      };
      if (!permissions.showAll) {
        window.history.back();
      }
    })
    this.$orders.subscribe(orders => {
      orders.forEach(order => {
        this.transactions.push({
          order_id: order.id,
          transaction_id: "#" + (order.paypal_orderId != null ? order.paypal_orderId : order.id),
          amount: order.total,
          delivery_status: order.status,
          pay_method: order.payment_type,
          date: new Date(order.updated_at+'').toLocaleString()
        })
      })

    })
  }
  ngAfterViewInit() {

  }
  /**
   * Getter $user
   * @return { Observable<User> }
   */
  public get $user(): Observable<User> {
    return this.store.select(getCurrentUser);
  }
  /**
     * Getter $orders
     * @return { Observable<Order[]> }
     */
  public get $orders(): Observable<Order[]> {
    return this.store.select(getAllOrders)
    // .pipe(
    //   map(orders => orders.map(order => order.items = JSON.parse(order.items).map(item => item.name + '(x' + item.quantity + ')'))),
    // );
  }

}
