import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { displaySuccess, displayWarning, displayError, displayInfo, setLoadingSpinner, setConfirmDeleteModalState } from "./shared.actions";
import { ToastrService } from "ngx-toastr";
import { map, tap } from "rxjs/operators";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Injectable()
export class SharedEffects {
  displaySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(displaySuccess),
        tap(action => {
          this.toastr.success(action.message, action.title);
        })
      ),
    { dispatch: false }
  );

  displayWarning$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(displayWarning),
        tap(action => {
          this.toastr.warning(action.message, action.title);
        })
      ),
    { dispatch: false }
  );

  displayError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(displayError),
        tap(action => {
          this.toastr.error(action.message, action.title);
        })
      ),
    { dispatch: false }
  );
  displayInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(displayInfo),
        tap(action => {
          this.toastr.error(action.message, action.title);
        })
      ),
    { dispatch: false }
  );

  toggleLoader$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setLoadingSpinner),
        tap(action => {
          action.status ? this.loader.start() : this.loader.stop()
        })
      ),
    { dispatch: false }
  );

  // setConfirmDeleteModalState$ = createEffect(
  //   () => {
  //     return this.actions$.pipe(
  //       ofType(setConfirmDeleteModalState),
  //       tap(action => {
  //         return action.open ? 
            
  //       })
  //     )
  //   }
  // );

  constructor(private actions$: Actions, private toastr: ToastrService, private loader: NgxUiLoaderService) { }
}
