import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { MessagesRoutingModule } from './messages-routing.module';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateMessageComponent } from './update-message/update-message.component';
import { StoreModule } from '@ngrx/store';
import { MessageEffects } from './store/message.effects';
import { messageReducer } from './store/message.reducers';
import { EffectsModule } from '@ngrx/effects';
import { RoleEffects } from '../roles/store/role.effects';
import { ListMessageComponent } from './list-message/list-message.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DndDirective } from 'src/app/direcitves/dnd.directive';
import { ProgressComponent } from 'src/app/shared/components/progress/progress.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [ListMessageComponent, UpdateMessageComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    MessagesRoutingModule,
    NgxFileDropModule,
    SharedModule,
    StoreModule.forFeature('messages', messageReducer),
    EffectsModule.forFeature([MessageEffects])
  ]
})
export class MessagesModule { }
