import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { Category } from 'src/app/entities/category';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, displayWarning, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { environment } from 'src/environments/environment';
import { getAllCategories, getSelectedCategoryId } from '../../categories/store/category.selectors';
import { updateCategory } from '../store/category.actions';
import { getSelectedCategory } from '../store/category.selectors';

@Component({
  selector: 'app-update-category',
  templateUrl: './update-category.component.html',
  styleUrls: ['./update-category.component.scss'],
})
export class UpdateCategoryComponent implements OnInit {
  public accountForm: FormGroup = new FormGroup(
    {
      parent_id: new FormControl(''),
      name: new FormControl('', Validators.required)
    },
  );
  public imageForm: FormGroup = new FormGroup(
    {
      image: new FormControl('')
    }
  );
  selected: Category;
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router,
    private attachmentService: AttachmentService
  ) { }


  ngOnInit() {
    this.store.dispatch(setLoadingSpinner({ status: true }))
    this.store.select(getSelectedCategory).subscribe((category: Category) => {
      console.log(category)
      this.store.dispatch(setLoadingSpinner({ status: false }))
      if (category == null)
        this.router.navigateByUrl('/categories/list-category');
      else {
        this.selected = category;
        if (this.selected.photo != '' && this.selected.photo != null) {
          this.files.push({ image: this.selected.photo, name: this.selected.photo.split('/')[1], progress: 100 })
          this.files[0].data = this.src(this.selected.photo);
        }
        this.accountForm.patchValue({
          parent_id: category.parent_id ? category.parent_id : '',
          name: category.name
        })
      }
    })

  }

  update() {
    if (this.accountForm.invalid) {
      if (this.accountForm.controls.name.invalid) {
        this.toastr.error('The category name field is required', 'Category name required !');
      }
    } else {
      this.disabled = true;
      if (this.filesToDelete.length != 0) {
        this.deleteFile(0).then(() => {
          if (this.filesToUpload.length != 0) {
            this.uploadFile(0).then(() => {
              let category: any = {
                parent_id: this.accountForm.controls.parent_id.value,
                name: this.accountForm.controls.name.value,
                photo: this.files[0].image
              };
              this.store.dispatch(updateCategory({ update: { id: this.selected.id, changes: category } }));
              this.disabled = false;
            })
          }
          else {
            let category: any = {
              parent_id: this.accountForm.controls.parent_id.value,
              name: this.accountForm.controls.name.value,
              photo: null
            };
            this.store.dispatch(updateCategory({ update: { id: this.selected.id, changes: category } }));
            this.disabled = false;
          }
        })
      }
      else if (this.filesToUpload.length != 0) {
        this.uploadFile(0).then(() => {
          let category: any = {
            parent_id: this.accountForm.controls.parent_id.value,
            name: this.accountForm.controls.name.value,
            photo: this.files[0].image
          };
          this.store.dispatch(updateCategory({ update: { id: this.selected.id, changes: category } }));
          this.disabled = false;
        })
      }
      else {
        let category: any = {
          parent_id: this.accountForm.controls.parent_id.value,
          name: this.accountForm.controls.name.value,
          photo: this.files.length != 0 ? this.files[0].image : null
        };
        this.store.dispatch(updateCategory({ update: { id: this.selected.id, changes: category } }));
        this.disabled = false;
      }
    }
  }
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];
  filesToUpload: any[] = [];
  filesToDelete: any[] = [];
  disabled = false;
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  prepareDeleteFile(index: number) {
    if (this.files.length >= index) {
      this.filesToDelete.push(this.files[index]);
      this.files.splice(index, 1);
    }
  }

  deleteFileToUpload(index: number) {
    if (this.filesToUpload.length >= index) {
      this.filesToUpload.splice(index, 1);
    }
  }
  deleteFile(index): Promise<any> {
    if (this.filesToDelete.length == 0)
      return;
    return new Promise((resolve, reject) => this.attachmentService.delete(this.filesToDelete[index].image).subscribe(
      (result) => { },
      (error: Error) => {
        this.store.dispatch(displayError({ message: error.message, title: '' }))
        reject(error)
      },
      () => {
        this.store.dispatch(displaySuccess({ title: 'Image deleted successfully ! ', message: '' }))
        this.filesToDelete.splice(index, 1);
        resolve(true);
      }))
  }
  restoreFile(index: number) {
    if (this.filesToDelete.length >= index) {
      if (this.filesToUpload.length > 0) this.deleteFileToUpload(0);

      this.files.push(this.filesToDelete[index]);
      this.filesToDelete.splice(index, 1);
    }
  }
  src(name) {
    return environment.baseUrl + "attachments/download/" + name
  }
  /**
   * Simulate the upload process
   */
  uploadFile(index: number): Promise<any> {
    if (index === this.filesToUpload.length) {
      return;
    }
    return new Promise((resolve, reject) => this.attachmentService.upload({ image: this.filesToUpload[index].data, ext: this.filesToUpload[index].ext, object_type: 'category', index: index })
      .subscribe(
        (result: any) => {
          this.filesToUpload[index].image = result.image;
        },
        (error: Error) => {
          this.store.dispatch(displayError({ message: error.message, title: '' }));
          reject(error)
        },
        () => {
          this.filesToUpload[index].progress = 100;
          this.files.push(this.filesToUpload[index]);
          this.deleteFileToUpload(index);
          this.store.dispatch(displaySuccess({ title: 'Image uploaded successfully ! ', message: '' }))
          resolve(true)
        }
      ));
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    if (files.length == 1) {
      let ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1, files[0].name.length);
      if (ext == 'png' || ext == 'jpg' || ext == 'webp' || ext == 'svg' || ext == 'jpeg') {
        if (this.files.length > 0) this.prepareDeleteFile(0);
        if (this.filesToUpload.length > 0) this.deleteFileToUpload(0);
        for (const item of files) {
          var reader = new FileReader();
          reader.readAsDataURL(<File>item);
          reader.onloadend = (_event) => {
            item.progress = 0;
            item.data = reader.result;
            item.ext = ext;
            this.filesToUpload.push(item);
            // this.fileDropEl.nativeElement.value = "";
            // this.uploadFile(0);
          };
        }

      }
      else this.store.dispatch(displayError({ message: 'Only Images of type png, jpg, webp, svg or jpeg are allowed', title: 'Invalid Image Type' }))
    }
    else this.store.dispatch(displayError({ message: 'Only one image is required for categories !', title: 'Image Upload Limit Exceeded' }))

  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  /**
   * Getter $selected
   * @return { Observable<Category> }
   */
   public get $selected(): Observable<Category> {
    return this.store.select(getSelectedCategory);
  }
  /**
   * Getter $selected
   * @return { Observable<Category> }
   */
   public get $selectedId(): Observable<number> {
    return this.store.select(getSelectedCategoryId);
  }
  /**
   * Getter $categories
   * @return { Observable<Category[]> }
   */
  public get $categories(): Observable<Category[]> {
    return this.store.pipe(
      select(getAllCategories),
      map(categories => categories.filter(category => category.parent_id == null && this.selected?.id != category.id ))
    );
  }
}
