import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CategoriesRoutingModule } from './categories-routing.module';
import { CreateCategoryComponent } from './create-category/create-category.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateCategoryComponent } from './update-category/update-category.component';
import { StoreModule } from '@ngrx/store';
import { CategoryEffects } from './store/category.effects';
import { categoryReducer } from './store/category.reducers';
import { EffectsModule } from '@ngrx/effects';
import { RoleEffects } from '../roles/store/role.effects';
import { ListCategoryComponent } from './list-category/list-category.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DndDirective } from 'src/app/direcitves/dnd.directive';
import { ProgressComponent } from 'src/app/shared/components/progress/progress.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CategoryTreeComponent } from './category-tree/category-tree.component';

@NgModule({
  declarations: [ListCategoryComponent, CreateCategoryComponent, UpdateCategoryComponent, CategoryTreeComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    CategoriesRoutingModule,
    NgxFileDropModule,
    SharedModule,
    StoreModule.forFeature('categories', categoryReducer),
    EffectsModule.forFeature([CategoryEffects])
  ]
})
export class CategoriesModule { }
