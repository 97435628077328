import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { isAuthenticated } from './components/auth/store/auth.selectors';
import { checkIdentity } from './components/auth/store/auth.actions';
@Injectable()
export class AppResolver implements Resolve<Observable<any>> {

  constructor(private store: Store<AppState>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    this.store.dispatch(setLoadingSpinner({ status: true}))
    return this.store
      .pipe(
        select(isAuthenticated),
        tap((connected) => {
          if (!connected) {
            this.store.dispatch(checkIdentity());
          }
          this.store.dispatch(setLoadingSpinner({ status: false}))
        }),
        filter(connected => connected),
        first()
      );
  }
}
