<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Update User</h5>
                </div>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Role">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="accountForm" class="needs-validation user-add">
                                        <h4>Role Details</h4>
                                        <div class="form-group row">
                                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                                First Name</label>
                                            <input formControlName="first_name" class="form-control col-xl-8 col-md-7"
                                                id="validationCustom0" type="text" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom1" class="col-xl-3 col-md-4"><span>*</span> Last
                                                Name</label>
                                            <input formControlName="last_name" class="form-control col-xl-8 col-md-7"
                                                id="validationCustom1" type="text" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom2" class="col-xl-3 col-md-4"><span>*</span>
                                                Email</label>
                                            <input formControlName="email" class="form-control col-xl-8 col-md-7"
                                                id="validationCustom2" type="email" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="phone" class="col-xl-3 col-md-4"><span>*</span>
                                                Phone</label>
                                            <input formControlName="phone" class="form-control col-xl-8 col-md-7"
                                                id="phone" type="phone" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="birthdate" class="col-xl-3 col-md-4">
                                                Birthdate</label>
                                            <input formControlName="birthdate" class="form-control col-xl-8 col-md-7"
                                                id="birthdate" type="date">
                                        </div>
                                        <div class="form-group row">
                                            <label for="gender" class="col-xl-3 col-md-4"><span>*</span>
                                                Gender</label>
                                            <select class="custom-select col-xl-8 col-md-7" formControlName="gender"
                                                name="gender" id="role">
                                                <option value="">--Select--</option>
                                                <option value="F">Female</option>
                                                <option value="M">Male</option>
                                            </select>
                                        </div>
                                        <div class="form-group row">
                                            <label for="address" class="col-xl-3 col-md-4">
                                                Address</label>
                                            <input formControlName="address" class="form-control col-xl-8 col-md-7"
                                                id="address" type="text">
                                        </div>
                                        <div class="form-group row">
                                            <label for="market" class="col-xl-3 col-md-4"><span>*</span>
                                                Store</label>
                                            <select class="custom-select col-xl-8 col-md-7" formControlName="market"
                                                name="market" id="role">
                                                <option value="">--Select--</option>
                                                <option *ngFor="let market of $markets | async" [value]="market.id">
                                                    {{market.name}}</option>
                                            </select>
                                        </div>

                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Role</label>
                                            <select class="custom-select col-xl-8 col-md-7" formControlName="role"
                                                name="role" id="role">
                                                <option value="">--Select--</option>
                                                <option *ngFor="let role of $roles | async" [value]="role.id">
                                                    {{role.name}}</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                    <div class="pull-right">
                        <button type="button" class="btn btn-primary" (click)="update()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->