import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../store/reducers';
import { getToken } from '../components/auth/store/auth.selectors';
import { exhaustMap, take } from 'rxjs/operators';
import { User } from '../entities/user';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient, private store: Store<AppState>) { }

  get(url) {

    return this.http.get(environment.baseUrl + url, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + (<User>JSON.parse(localStorage.getItem('user')))?.token,
        //'Content-Type': 'application/json'
      })
    })


  }

  post(url, data) {
    return this.http.post(environment.baseUrl + url, data, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + (<User>JSON.parse(localStorage.getItem('user')))?.token,
        'Content-Type': 'application/json'
      })
    })
  }

  put(url, data) {
    return this.http.put(environment.baseUrl + url, data, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + (<User>JSON.parse(localStorage.getItem('user')))?.token,
        'Content-Type': 'application/json'
      })
    })
  }

  delete(url) {
    return this.http.delete(environment.baseUrl + url, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + (<User>JSON.parse(localStorage.getItem('user')))?.token,
        //'Content-Type': 'application/json'
      })
    })
  }
}