import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducers';
export const AUTH_STATE_NAME = 'auth';

const getAuthState = createFeatureSelector<AuthState>(AUTH_STATE_NAME);

export const isAuthenticated = createSelector(getAuthState, (state) => {
  return state.currentUser ? true : false;
});

export const getToken = createSelector(getAuthState, (state) => {
  return state.currentUser ? state.currentUser.token : null;
});

export const getCurrentUser = createSelector(getAuthState, (state) => {
  return state.currentUser;
});
