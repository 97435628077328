import { areMessagesLoaded } from './store/message.selectors';
import { loadMessages, messagesLoaded } from './store/message.actions';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';

@Injectable()
export class MessagesResolver implements Resolve<Observable<any>> {

  constructor(private store: Store<AppState>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store
      .pipe(

        select(areMessagesLoaded),
        
        tap((messagesLoaded) => {
          if (!messagesLoaded) {
            this.store.dispatch(setLoadingSpinner({ status: true }))
            this.store.dispatch(loadMessages());
          }
        }),
        filter(messagesLoaded => messagesLoaded),
        first()
      );
  }
}
