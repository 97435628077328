import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UpdateMessageComponent } from './update-message/update-message.component';
import { MessagesResolver } from './messages.resolver';
import { ListMessageComponent } from './list-message/list-message.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-message',
        component: ListMessageComponent,
        resolve: {
          categories: MessagesResolver
        },
        data: {
          title: "Message List",
          breadcrumb: "Message List"
        }
      },
      {
        path: 'update-message',
        component: UpdateMessageComponent,
        data: {
          title: "Create Message",
          breadcrumb: "Create Message"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MessagesResolver]
})
export class MessagesRoutingModule { }
