import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Item } from '../entities/item';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(private http: HttpService) { }

  all(query: string = null): Observable<Item[]> {
    return <Observable<Item[]>>(this.http.get("items?" + query))
  }

  add(data: any) {
    return this.http.post("items", data)
  }

  update(id: number, object: object) {
    return this.http.put('items/' + id, object)
  }

  delete(id: number) {
    return this.http.delete('items/' + id)
  }

  get(id: number) {
    return this.http.get('items/' + id)
  }
}
