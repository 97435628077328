import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Role } from 'src/app/entities/role';
import { RolePermission } from 'src/app/entities/rolePermission';
import { AppState } from 'src/app/store/reducers';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { getAllRoles } from '../../roles/store/role.selectors';
import { updateRole } from '../store/role.actions';
import { getSelectedRole } from '../store/role.selectors';

@Component({
  selector: 'app-update-role',
  templateUrl: './update-role.component.html',
  styleUrls: ['./update-role.component.scss'],
})
export class UpdateRoleComponent implements OnInit {
  private permissions: String[] = [
    'User',
    'Role',
    'Category',
    'Product',
    'Store',
    'Coupon',
    'Order'
  ];
  roleForm = new FormGroup({
    name: new FormControl('', Validators.required),
    is_administrator: new FormControl({ defaultValue: false }, Validators.required)
  });
  permissionForm = new FormGroup({});


  selected: Role;
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {

    this.store.dispatch(setLoadingSpinner({ status: true }))
    this.store.select(getSelectedRole).subscribe((role: Role) => {
      this.store.dispatch(setLoadingSpinner({ status: false }))
      if (role == null)
        this.router.navigateByUrl('/roles/list-role');
      else {
        this.selected = role;
        console.log(this.selected)
        this.permissions.forEach((permission: String) => {
          // this.roleForm.controls.is_administrator.patchValue(false);
          this.permissionForm.addControl('create_' + permission.toLowerCase(), new FormControl({ defaultValue: false }, Validators.required));
          this.permissionForm.get('create_' + permission.toLowerCase()).patchValue(this.selected.permissions?.find((element) => element.name == 'create_' + permission.toLowerCase())?.is_permitted ? true : false);
          this.permissionForm.addControl('update_' + permission.toLowerCase(), new FormControl({ defaultValue: false }, Validators.required));
          this.permissionForm.get('update_' + permission.toLowerCase()).patchValue(this.selected.permissions?.find((element) => element.name == 'update_' + permission.toLowerCase())?.is_permitted ? true : false);
          this.permissionForm.addControl('delete_' + permission.toLowerCase(), new FormControl({ defaultValue: false }, Validators.required));
          this.permissionForm.get('delete_' + permission.toLowerCase()).patchValue(this.selected.permissions?.find((element) => element.name == 'delete_' + permission.toLowerCase())?.is_permitted ? true : false);
          this.permissionForm.addControl('show_all_' + permission.toLowerCase(), new FormControl({ defaultValue: false }, Validators.required));
          this.permissionForm.get('show_all_' + permission.toLowerCase()).patchValue(this.selected.permissions?.find((element) => element.name == 'show_all_' + permission.toLowerCase())?.is_permitted ? true : false);
        });
        this.roleForm.patchValue({
          is_administrator: role.is_administrator == true ? true : false,
          name: role.name
        })
        // role.permissions.forEach((element: RolePermission) => {
        //   let key = element.name;
        //   this.permissionForm.setValue({ key: element.is_permitted })
        // })
      }
    })
    // console.log(this.permissionForm);
  }

  update() {
    if (this.roleForm.invalid) {
      if (this.roleForm.controls.role.invalid) {
        this.toastr.error('The role name field is required', 'Role name required !');
      }
    } else {
      this.store.dispatch(setLoadingSpinner({ status: true }));
      let role: Role = { id: this.selected.id, name: this.roleForm.controls.name.value, is_administrator: this.roleForm.controls.is_administrator.value, permissions: [] };
      Object.keys(this.permissionForm.controls).forEach((key, index) => {
        role.permissions.push({ id: this.selected.permissions.find((permission) => permission.name == key).id, name: key, is_permitted: Object.values(this.permissionForm.controls)[index].value });
      });
      this.store.dispatch(updateRole({ update: { id: this.selected.id, changes: role } }));

    }
  }
  getControllerName(index): String {
    return Object.keys(this.permissionForm.controls)[index];
  }
  /**
     * Getter $permissions
     * @return { String[] }
     */
  public get $permissions(): String[] {
    return this.permissions;
  }
  /**
   * Getter $selected
   * @return { Observable<Role> }
   */
  public get $selected(): Observable<Role> {
    return this.store.select(getSelectedRole);
  }
  /**
   * Getter $roles
   * @return { Observable<Role[]> }
   */
  public get $roles(): Observable<Role[]> {
    return this.store.select(getAllRoles);
  }
}
