import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Category } from 'src/app/entities/category';
import { categoryActionTypes, categoriesLoaded } from './category.actions';

export interface CategoryState extends EntityState<Category> {
  categoriesLoaded: boolean;
  selectedCategoryId: number;
}

export const adapter: EntityAdapter<Category> = createEntityAdapter<Category>({
  sortComparer: (a: Category, b: Category) => b.updated_at.localeCompare(<string>a.updated_at),
});

export const initialState = adapter.getInitialState({
  categoriesLoaded: false,
  selectedCategoryId: null
});

export const categoryReducer = createReducer(
  initialState,

  on(categoryActionTypes.categoriesLoaded, (state, action) => {
    return adapter.setAll(
      action.categories,
      { ...state, categoriesLoaded: true }
    );
  }),

  on(categoryActionTypes.createCategorySuccess, (state, action) => {
    return adapter.addOne(action.category, state);
  }),
  on(categoryActionTypes.storeCategory, (state, action) => {
    console.log(action)
    return adapter.addOne(action.category, state);
  }),

  on(categoryActionTypes.deleteCategory, (state, action) => {
    return adapter.removeOne(action.categoryId, state);
  }),
  on(categoryActionTypes.removeCategory, (state, action) => {
    return adapter.removeOne(action.categoryId, state);
  }),
  on(categoryActionTypes.updateCategorySuccess, (state, action) => {
    return adapter.updateOne(action.category, { ...state, selectedCategoryId: null });
  }),
  on(categoryActionTypes.changeCategory, (state, action) => {
    return adapter.updateOne(action.category, state);
  }),
  on(categoryActionTypes.startUpdateCategory, (state, action) => {
    return { ...state, selectedCategoryId: action.categoryId }
  }),
  on(categoryActionTypes.startDeleteCategory, (state, action) => {
    return { ...state, selectedCategoryId: action.categoryId }
  }),
);

export const { selectAll } = adapter.getSelectors();

export const selectedCategoryId = (state) => state.selectedCategoryId;
