import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { Observable } from 'rxjs';
import { Order } from 'src/app/entities/order';
import { User } from 'src/app/entities/user';
import { AppState } from 'src/app/store/reducers';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { startUpdateOrder } from '../store/order.actions';
import { getAllOrders } from '../store/order.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  orders: any[] = [];
  @ViewChild('table')
  smartTable: Ng2SmartTableComponent;
  constructor(private store: Store<AppState>,) {
  }
  public actions = []

  public settings = {
    actions: {
      position: 'left',
      custom: this.actions,
      edit: false,
      delete: false,
      add: false
    },
    columns: {
      id: {
        title: '#'
      },
      items: {
        title: 'Products'
      },
      customer: {
        title: 'Customer',
        valuePrepareFunction: (customer: User) => {
          return customer?.extra?.full_name;
        }
      },
      total: {
        title: 'Total(TTC)'
      },
      payment_type: {
        title: 'Payment Type',
        type: 'html',

        valuePrepareFunction: (value) => {
          return '<div class="badge badge-pill badge-info">' + value.toUpperCase() + '</div>';
        },
      },
      status: {
        title: 'Status',
        type: 'html',

        valuePrepareFunction: (value) => {
          return value.includes('Paid') ? '<div class="badge badge-pill badge-success">' + value.toUpperCase() + '</div>' : '<div class="badge badge-pill badge-warning">' + value.toUpperCase() + '</div>';
        },
      },
      creator: {
        title: 'Created by',
        valuePrepareFunction: (creator: User) => {
          return creator?.extra?.full_name;
        }
      },
      editor: {
        title: 'Updated by',
        valuePrepareFunction: (editor: User) => {
          return editor?.extra?.full_name;
        }
      },
      created_at: {
        title: 'Created at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        }
      },
      updated_at: {
        title: 'Updated at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        }
      },
    },
  };
  ngOnInit() {
    this.$user.subscribe(user => {
      let permissions = {
        create: user.role.permissions.find(permission => permission.name == 'create_order').is_permitted,
        update: user.role.permissions.find(permission => permission.name == 'update_order').is_permitted,
        delete: user.role.permissions.find(permission => permission.name == 'delete_order').is_permitted,
        showAll: user.role.permissions.find(permission => permission.name == 'show_all_order').is_permitted,
      };
      if (!permissions.showAll) {
        window.history.back();
      }
      if (permissions.update) {
        this.actions.push({
          name: 'update_order',
          title: '<i class="fa fa-pencil" title="Update Order"></i>'
        })
      }
    })
    this.$orders.subscribe(orders => {
      orders.forEach(order => {
        this.orders.push({
          items: JSON.parse(order.items).map(item => item.name + '(x' + item.quantity + ')'),
          customer: order.customer,
          total: order.total,
          status: order.status,
          payment_type: order.payment_type,
          creator: order.creator,
          editor: order.editor,
          created_at: order.created_at,
          updated_at: order.updated_at,
        })
      })

    })
  }
  ngAfterViewInit() {
    this.smartTable.custom.subscribe((dataObject: any) => {
      switch (dataObject.action) {
        case "update_order":
          this.store.dispatch(startUpdateOrder({ orderId: (<Order>dataObject.data).id }))
          break;
      }
    })
  }
  /**
   * Getter $user
   * @return { Observable<User> }
   */
  public get $user(): Observable<User> {
    return this.store.select(getCurrentUser);
  }
  /**
     * Getter $orders
     * @return { Observable<Order[]> }
     */
  public get $orders(): Observable<Order[]> {
    return this.store.select(getAllOrders)
    // .pipe(
    //   map(orders => orders.map(order => order.items = JSON.parse(order.items).map(item => item.name + '(x' + item.quantity + ')'))),
    // );
  }
}
