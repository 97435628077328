<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Add Product</h5>
                </div>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Product">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="itemForm" class="needs-validation user-add">
                                        <h4>Product Details</h4>
                                        <div class="form-group row">
                                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                                Name</label>
                                            <input formControlName="name" class="form-control col-xl-8 col-md-7"
                                                id="validationCustom0" type="text" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="market" class="col-xl-3 col-md-4"><span>*</span>
                                                Store</label>
                                            <select class="custom-select col-xl-8 col-md-7" formControlName="market" name="market" id="role">
                                                <option value="">--Select--</option>
                                                <option *ngFor="let market of $markets | async" [value]="market.id">
                                                    {{market.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Category</label>
                                            <select class="custom-select col-xl-8 col-md-7" formControlName="category" name="category" id="category" form="carform">
                                                <option value="">--Select--</option>
                                                <option *ngFor="let category of $categories | async" [value]="category.id">
                                                    {{category.name}}</option>
                                            </select>
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Color</label>
                                            <select class="custom-select col-xl-8 col-md-7" formControlName="color" name="color" id="color" form="carform">
                                                <option value="">--Select--</option>
                                                <option value="white">White</option>
                                                <option value="black">Black</option>
                                                <option value="gray">Gray</option>
                                                <option value="brown">Brown</option>
                                                <option value="green">Green</option>
                                                <option value="red">Red</option>
                                                <option value="yellow">Yellow</option>
                                            </select>
                                        </div>
                                        <div class="form-group row">
                                            <label for="price" class="col-xl-3 col-md-4"><span>*</span>
                                                Price</label>
                                            <input formControlName="price" class="form-control col-xl-8 col-md-7"
                                                id="price" type="number" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="old_price" class="col-xl-3 col-md-4">
                                                Old Price</label>
                                            <input formControlName="old_price" class="form-control col-xl-8 col-md-7"
                                                id="old_price" type="string">
                                        </div>
                                        <div class="form-group row">
                                            <label for="quantity" class="col-xl-3 col-md-4"><span>*</span>
                                                Quantity</label>
                                            <input formControlName="quantity" class="form-control col-xl-8 col-md-7"
                                                id="quantity" type="number" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="width" class="col-xl-3 col-md-4"><span></span>
                                                Width</label>
                                            <input formControlName="width" class="form-control col-xl-8 col-md-7"
                                                id="width" type="number" >
                                        </div>
                                        <div class="form-group row">
                                            <label for="height" class="col-xl-3 col-md-4"><span></span>
                                                Height</label>
                                            <input formControlName="height" class="form-control col-xl-8 col-md-7"
                                                id="height" type="number" >
                                        </div>
                                        <div class="form-group row">
                                            <label for="length" class="col-xl-3 col-md-4"><span></span>
                                                Length</label>
                                            <input formControlName="length" class="form-control col-xl-8 col-md-7"
                                                id="length" type="number" >
                                        </div>
                                        <div class="form-group row">
                                            <label for="weight" class="col-xl-3 col-md-4"><span></span>
                                                Weight</label>
                                            <input formControlName="weight" class="form-control col-xl-8 col-md-7"
                                                id="weight" type="number" >
                                        </div>
                                        
                                        <div class="form-group row">
                                            <label for="description" class="col-xl-3 col-md-4"><span>*</span>
                                                Description</label>
                                            <textarea formControlName="description" class="form-control col-xl-8 col-md-7"
                                                id="description" type="text" required=""></textarea>
                                        </div>

                                        <div class="form-group row">
                                            <label for="name" class="col-xl-3 col-md-4"><span>*</span>
                                                Is Trending Product ?</label>
                                            <div class="col-xl-9 col-sm-8">
                                                <div
                                                    class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated pb-0">
                                                    <label class="d-block mb-0" for="is_trending_yes">
                                                        <input class="radio_animated" id="is_trending_yes"
                                                            name="is_trending" [value]="true" type="radio"
                                                            formControlName="is_trending" />
                                                        Yes
                                                    </label>
                                                    <label class="d-block mb-0" for="is_trending_no">
                                                        <input class="radio_animated" id="is_trending_no"
                                                            type="radio" [value]="false" name="is_trending"
                                                            formControlName="is_trending" />
                                                        No
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                    <p class="disclaimer">PICTURE MUST BE (860x1090)</p>
                                    <form [formGroup]="imageForm" enctype="multipart/form-data">
                                        <div class="container" appDnd (fileDropped)="onFileDropped($event)">
                                            <input type="file" #fileDropRef id="fileDropRef" multiple
                                                accept="image/png,image/jpg,image/webp,image/svg,image/jpeg"
                                                (change)="fileBrowseHandler($event.target.files)"
                                                formControlName="image" />
                                            <img src="assets/images/ic-upload-file.svg" alt="">
                                            <h3>Drag and drop file here</h3>
                                            <h3>or</h3>
                                            <label for="fileDropRef">Browse for file</label>
                                        </div>
                                        <div class="files-list">
                                            <div class="single-file" *ngFor="let file of files; let i = index">
                                                <img [src]="file.data" width="45px" alt="file">
                                                <div class="info">
                                                    <h4 class="name">
                                                        {{ file?.name }}
                                                    </h4>
                                                    <p class="size">
                                                        {{ formatBytes(file?.size) }}
                                                    </p>
                                                    <app-progress [progress]="file?.progress"></app-progress>
                                                </div>
                                                <img src="assets/images/ic-delete-file.svg" class="delete" width="20px"
                                                    alt="file" (click)="deleteFile(i)">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                    <div class="pull-right">
                        <button type="button" class="btn btn-primary" (click)="add()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->