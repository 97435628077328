<div class="modal-header">
    <h5 class="modal-title">This action is irreversible</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>Do you want to delete the record anyway ? </p>
</div>
<div class="modal-footer">
    <button type="button" (click)="onDelete()" class="btn btn-primary">Delete</button>
    <button type="button" (click)="onCancel()" class="btn btn-danger" data-dismiss="modal">Close</button>
</div>