import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, displayWarning, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { createMarket } from '../store/market.actions';
import { environment } from 'src/environments/environment';
import { AttachmentService } from 'src/app/services/attachment.service';
import { User } from 'src/app/entities/user';
import { getCurrentUser } from '../../auth/store/auth.selectors';

@Component({
  selector: 'app-create-market',
  templateUrl: './create-market.component.html',
  styleUrls: ['./create-market.component.scss'],
})
export class CreateMarketComponent implements OnInit {

  public marketForm: FormGroup = new FormGroup(
    {
      name: new FormControl('', Validators.required)
    }
  );
  public imageForm: FormGroup = new FormGroup(
    {
      image: new FormControl('')
    }
  );
  constructor(
    private toastr: ToastrService,
    private store: Store<AppState>,
    private attachmentService: AttachmentService

  ) { }
  public data: any
  ngOnInit() {
    this.$user.subscribe(user => {
      console.log(user)
      if (!(user.role.permissions.find(permission => permission.name == 'create_store').is_permitted)) {
        window.history.back();
      }
    })
  }

  add() {
    if (this.marketForm.invalid) {
      if (this.marketForm.controls.name.invalid) {
        this.toastr.error('The market name field is required !', 'market name is required');
      }
    } else {
      if (this.files.length != 0) {
        this.fileDropEl.nativeElement.value = "";
        this.uploadFile(0).then(() => {
          let market: any = {
            name: this.marketForm.controls.name.value,
            photo: this.files[0].image
          };
          this.store.dispatch(setLoadingSpinner({ status: true }))
          this.store.dispatch(createMarket({ market }))
        });
      }
      else {
        let market: any = {
          name: this.marketForm.controls.name.value,
          photo: null
        };
        this.store.dispatch(setLoadingSpinner({ status: true }))
        this.store.dispatch(createMarket({ market }))
      }
    }
  }
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files.length >= index) {
      this.files.splice(index, 1);
    }
  }

  src(name) {
    return environment.baseUrl + "attachments/download/" + name
  }
  /**
   * Simulate the upload process
   */
  uploadFile(index: number): Promise<any> {
    if (index === this.files.length) {
      return;
    }
    return new Promise((resovle, reject) => this.attachmentService.upload({ image: this.files[index].data, ext: this.files[index].ext, object_type: 'store', index: index })
      .subscribe(
        (result: any) => {
          this.files[index].image = result.image;
          setTimeout(() => {
            const progressInterval = setInterval(() => {
              if (this.files[index].progress === 100) {
                clearInterval(progressInterval);
                // this.uploadFile(index + 1);
              } else {
                if (this.files[index].progress != 95) this.files[index].progress += 5;
              }
            }, 200);
          }, 1000);
        },
        (error: Error) => {
          this.store.dispatch(displayError({ message: error.message, title: '' }))
          reject(error);
        },
        () => {
          this.files[index].progress = 100;
          this.store.dispatch(displaySuccess({ title: 'Image uploaded successfully ! ', message: '' }))
          resovle(true);
        }
      ));
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    if (files.length == 1) {
      let ext = files[0].name.substr(files[0].name.lastIndexOf('.') + 1, files[0].name.length);
      if (ext == 'png' || ext == 'jpg' || ext == 'webp' || ext == 'svg' || ext == 'jpeg') {
        if (this.files.length > 0) this.deleteFile(0);
        for (const item of files) {
          var reader = new FileReader();
          reader.readAsDataURL(<File>item);
          reader.onloadend = (_event) => {
            item.progress = 0;
            item.data = reader.result;
            item.ext = ext;
            this.files.push(item);
          };
        }
      }
      else this.store.dispatch(displayError({ message: 'Only Images of type png, jpg, webp, svg or jpeg are allowed', title: 'Invalid Image Type [' + ext + ']' }))
    }
    else this.store.dispatch(displayError({ message: 'Only one image is required for markets !', title: 'Image Upload Limit Exceeded' }))

  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  /**
   * Getter $user
   * @return { Observable<User> }
   */
  public get $user(): Observable<User> {
    return this.store.select(getCurrentUser);
  }
}
