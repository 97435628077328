import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { RolesRoutingModule } from './roles-routing.module';
import { ListRoleComponent } from './list-role/list-role.component';
import { CreateRoleComponent } from './create-role/create-role.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsComponent } from './permissions/permissions.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { RoleEffects } from './store/role.effects';
import { roleReducer } from './store/role.reducers';
import { UpdateRoleComponent } from './update-role/update-role.component';

@NgModule({
  declarations: [ListRoleComponent, CreateRoleComponent, UpdateRoleComponent, PermissionsComponent],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    RolesRoutingModule,
    StoreModule.forFeature('roles', roleReducer),
    EffectsModule.forFeature([RoleEffects])
  ]
})
export class RolesModule { }
