import { marketActionTypes, deleteMarket, updateMarketSuccess, createMarketSuccess } from './market.actions';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MarketService } from 'src/app/services/market.service';
import { Market } from 'src/app/entities/market';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { from, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';


@Injectable()
export class MarketEffects {

  loadMarkets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(marketActionTypes.loadMarkets),
      concatMap(() => this.marketService.all("with=creator,editor")),
      map((markets: Market[]) => marketActionTypes.marketsLoaded({ markets })),
      tap(() => { this.store.dispatch(setLoadingSpinner({ status: false })) }),
    )
  });   

  createMarket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(marketActionTypes.createMarket),
      concatMap((action) => this.marketService.add(action.market)),
      map((market: Market) => {
        this.store.dispatch(createMarketSuccess({ market }))
      }),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Market Creation failed'
          }))
        return of();
      })
    )


  }, { dispatch: false }
  );

  deleteMarket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(marketActionTypes.deleteMarket),
      exhaustMap(
        (action) => {
          return this.marketService.delete(action.marketId).pipe(
            tap(() => {

              this.store.dispatch(setLoadingSpinner({ status: false }));

              this.store.dispatch(displaySuccess({ message: 'Market Deleted Successfully!', title: '' }));
            }),
            catchError((errResp: any) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              if (errResp != null)
                this.store.dispatch(displayError({
                  message: JSON.stringify(errResp.error.message), title: 'Market Delete failed'
                }))
              return of();
            })
          );
        })
    )
  }, { dispatch: false });

  updateStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(marketActionTypes.startUpdateMarket),
      tap(() => this.router.navigateByUrl('/stores/update-store'))
    )
  }, { dispatch: false });

  deleteStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(marketActionTypes.startDeleteMarket),
      exhaustMap((action) => this.runDialog(ConfirmDeleteComponent, action.marketId)))
  }, { dispatch: false });

  runDialog = (content, marketId) => {
    const modalRef = this.modalService.open(content, { centered: true });
    modalRef.componentInstance.onConfirm = () => {
      this.store.dispatch(deleteMarket({ marketId }))
    };
    return from(modalRef.result);
  };
  updateMarket$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(marketActionTypes.updateMarket),
      concatMap((action) => this.marketService.update(<number>action.update.id, action.update.changes)),
      map((market: Market) => this.store.dispatch(updateMarketSuccess({ market: { id: market.id, changes: market } }))),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Market Update failed'
          }))
        return of();
      })
    )

  }, { dispatch: false });
  createMarketSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(marketActionTypes.createMarketSuccess),
      tap(() => {
        this.store.dispatch(displaySuccess({ message: 'Market Created Successfully!', title: '' }))
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.router.navigateByUrl('/stores/list-store');
      })
    )
  }, { dispatch: false })
  updateMarketSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(marketActionTypes.updateMarketSuccess),
      tap(() => {
        this.store.dispatch(displaySuccess({ message: 'Market Updated Successfully!', title: '' }))
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.router.navigateByUrl('/stores/list-store');
      })
    )
  }, { dispatch: false })
  constructor(private marketService: MarketService, private actions$: Actions, private router: Router, private store: Store<AppState>, private modalService: NgbModal) { }
}