<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Store Details</h5>
        </div>
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-secondary" [routerLink]="'/stores/create-store'">Create Store</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable category-image">
                <div class="table-responsive">
                    <ng2-smart-table #table [settings]="settings" [source]="$markets | async"></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>