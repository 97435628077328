import { FormGroup } from '@angular/forms';
import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/entities/user';
export const LOGIN_START = '[Auth Action] login start';
export const LOGIN_SUCCESS = '[Auth Action] login Success';
export const LOGIN_FAIL = '[Auth Action] login Fail';
export const CHECK_IDENTITY = '[Auth Action] check identity';

export const AUTO_LOGIN_ACTION = '[Auth Action] auto login';
export const LOGOUT_ACTION = '[Auth Action] logout';

export const loginStart = createAction(
  LOGIN_START,
  props<{ validEmail: boolean; validPassword: boolean; body: any }>()
);
export const checkIdentity = createAction(
  CHECK_IDENTITY,
);
export const loginSuccess = createAction(
  LOGIN_SUCCESS,
  props<{ user: User; redirect: boolean }>()
);
export const loginFail = createAction(
  LOGIN_FAIL,
  props<{ error: any; }>()
);

export const autoLogin = createAction(AUTO_LOGIN_ACTION, props<{ redirect: boolean }>());
export const autoLogout = createAction(LOGOUT_ACTION);
