import { messageActionTypes, deleteMessage, updateMessageSuccess } from './message.actions';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'src/app/services/message.service';
import { Message } from 'src/app/entities/message';
import { select, Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, setConfirmDeleteModalState, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { from, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { getAllMessages } from './message.selectors';

@Injectable()
export class MessageEffects {

  loadMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(messageActionTypes.loadMessages),
      concatMap(() => this.messageService.all("with=creator,editor")),
      map((messages: Message[]) => messageActionTypes.messagesLoaded({ messages })),
      tap(() => { this.store.dispatch(setLoadingSpinner({ status: false })) }),
    )
  });

  deleteMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(messageActionTypes.deleteMessage),
      exhaustMap(
        (action) => {
          return this.messageService.delete(action.messageId).pipe(
            tap(() => {

              this.store.dispatch(setLoadingSpinner({ status: false }));

              this.store.dispatch(displaySuccess({ message: 'Message Deleted Successfully!', title: '' }));
            }),
            catchError((errResp: any) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              if (errResp != null)
                this.store.dispatch(displayError({
                  message: JSON.stringify(errResp.error.message), title: 'Message Delete failed'
                }))
              return of();
            })
          );
        })
    )
  }, { dispatch: false });

  updateStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(messageActionTypes.startUpdateMessage),
      tap(() => this.router.navigateByUrl('/messages/update-message'))
    )
  }, { dispatch: false });

  deleteStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(messageActionTypes.startDeleteMessage),
      exhaustMap((action) => this.runDialog(ConfirmDeleteComponent, action.messageId)))
  }, { dispatch: false });

  runDialog = (content, messageId) => {
    const modalRef = this.modalService.open(content, { centered: true });
    modalRef.componentInstance.onConfirm = () => {
      this.store.dispatch(deleteMessage({ messageId }))
    };
    return from(modalRef.result);
  };
  updateMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(messageActionTypes.updateMessage),
      concatMap((action) => this.messageService.update(<number>action.update.id, action.update.changes)),
      map((message: Message) => this.store.dispatch(updateMessageSuccess({ message: { id: message.id, changes: message } }))),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        return of();
      })
    )

  }, { dispatch: false });

  updateMessageSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(messageActionTypes.updateMessageSuccess),
      tap(() => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
      })
    )
  }, { dispatch: false })
  constructor(private messageService: MessageService, private actions$: Actions, private router: Router, private store: Store<AppState>, private modalService: NgbModal) { }
}