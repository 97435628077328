import { RoleState, selectAll, selectedRoleId } from './role.reducers';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export const roleFeatureSelector = createFeatureSelector<RoleState>('roles');

export const getAllRoles = createSelector(
  roleFeatureSelector,
  selectAll
);
export const getSelectedRoleId = createSelector(
  roleFeatureSelector,
  state => state.selectedRoleId
);

export const getSelectedRole = createSelector(
  roleFeatureSelector,
  state => state.selectedRoleId != null ? state.entities[state.selectedRoleId] : null
);

export const areRolesLoaded = createSelector(
  roleFeatureSelector,
  state => state.rolesLoaded
);