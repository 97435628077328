import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { Observable } from 'rxjs';
import { Market } from 'src/app/entities/market';
import { User } from 'src/app/entities/user';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AppState } from 'src/app/store/reducers';
import { displayError } from 'src/app/store/Shared/shared.actions';
import { environment } from 'src/environments/environment';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { startDeleteMarket, startUpdateMarket } from '../store/market.actions';
import { getAllMarkets } from '../store/market.selectors';

@Component({
  selector: 'app-list-market',
  templateUrl: './list-market.component.html',
  styleUrls: ['./list-market.component.scss']
})
export class ListMarketComponent implements OnInit {
  public market_list = []
  markets: Market[] = []
  @ViewChild('table')
  smartTable: Ng2SmartTableComponent;

  constructor(
    private store: Store<AppState>,
    private attachmentService: AttachmentService
  ) {
  }

  public actions = []

  public settings = {
    actions: {
      position: 'left',
      custom: this.actions,
      edit: false,
      delete: false,
      add: false
    },
    columns: {
      photo: {
        title: 'Logo',
        type: 'html',
        valuePrepareFunction: (photo) => {
          return photo == null || photo == '' ? '' : '<img src="' + this.src(photo) + '"height=100"></img>';
        },
      },
      name: {
        title: 'Name'
      },
      creator: {
        title: 'Created by',
        valuePrepareFunction: (creator: User) => {
          return creator?.extra?.full_name;
        }
      },
      editor: {
        title: 'Updated by',
        valuePrepareFunction: (editor: User) => {
          return editor?.extra?.full_name;
        }
      },
      created_at: {
        title: 'Created at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        }
      },
      updated_at: {
        title: 'Updated at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        }
      },
    },
  };

  ngOnInit() {
    this.$user.subscribe(user => {
      let permissions = {
        create: user.role.permissions.find(permission => permission.name == 'create_store').is_permitted,
        update: user.role.permissions.find(permission => permission.name == 'update_store').is_permitted,
        delete: user.role.permissions.find(permission => permission.name == 'delete_store').is_permitted,
        showAll: user.role.permissions.find(permission => permission.name == 'show_all_store').is_permitted,
      };
      if(!permissions.showAll){
        window.history.back();
      }
      if (permissions.update) {
        this.actions.push({
          name: 'update_market',
          title: '<i class="fa fa-pencil" title="Update Store"></i>'
        })
      }
      if (permissions.delete) {
        this.actions.push({
          name: 'delete_market',
          title: '<i class="fa fa-trash" title="Detele Store"></i>'
        })
      }
    })
  }
  src(name) {
    return environment.baseUrl + "attachments/download/" + name
  }
  ngAfterViewInit() {
    this.smartTable.custom.subscribe((dataObject: any) => {
      switch (dataObject.action) {
        case "update_market":
          this.store.dispatch(startUpdateMarket({ marketId: (<Market>dataObject.data).id }))
          break;
        case 'delete_market':
          this.store.dispatch(startDeleteMarket({ marketId: (<Market>dataObject.data).id }))
          break;
      }
    })
  }
  /**
   * Getter $markets
   * @return { Observable<Market[]> }
   */
  public get $markets(): Observable<Market[]> {
    return this.store.select(getAllMarkets);
  }
  /**
   * Getter $user
   * @return { Observable<User> }
   */
  public get $user(): Observable<User> {
    return this.store.select(getCurrentUser);
  }

}

