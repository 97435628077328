<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Update User</h5>
                </div>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Category">
                            <ng-template ngbTabContent>
                                <div class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="accountForm" class="needs-validation user-add">
                                        <h4>Category Details</h4>
                                        <div class="form-group row">
                                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span>
                                                Category Name</label>
                                            <input formControlName="name" class="form-control col-xl-8 col-md-7"
                                                id="validationCustom0" type="text" required="">
                                        </div>
                                        <div class="form-group row">
                                            <label for="validationCustom4" class="col-xl-3 col-md-4"><span>*</span>
                                                Parent</label>
                                            <select class="custom-select col-xl-8 col-md-7" formControlName="parent_id"
                                                name="parent" id="parent" form="carform">
                                                <option value="">--Select--</option>
                                                <option [value]="parent.id" *ngFor="let parent of $categories | async"
                                                    >
                                                    {{parent.name}}</option>
                                            </select>
                                        </div>
                                    </form>
                                    <p class="disclaimer">PICTURE MUST BE (720x935)</p>
                                    <form [formGroup]="imageForm" enctype="multipart/form-data">
                                        <div class="container" appDnd (fileDropped)="onFileDropped($event)">
                                            <input type="file" #fileDropRef id="fileDropRef"
                                                accept="image/png,image/jpg,image/webp,image/svg,image/jpeg"
                                                (change)="fileBrowseHandler($event.target.files)"
                                                formControlName="image" />
                                            <img src="assets/images/ic-upload-file.svg" alt="">
                                            <h3>Drag and drop file here</h3>
                                            <h3>or</h3>
                                            <label for="fileDropRef">Browse for file</label>
                                        </div>
                                        <div class="files-list">
                                            <h3>Files</h3>
                                            <div class="single-file" *ngFor="let file of files; let i = index">
                                                <img [src]="file.data" width="45px" alt="file">
                                                <div class="info">
                                                    <h4 class="name">
                                                        {{ file?.name }}
                                                    </h4>
                                                    <app-progress [progress]="file?.progress"></app-progress>
                                                </div>
                                                <img src="assets/images/ic-delete-file.svg" class="delete" width="20px"
                                                    alt="file" (click)="prepareDeleteFile(i)">
                                            </div>
                                        </div>
                                        <div class="files-list">
                                            <h3>Files To Upload</h3>
                                            <div class="single-file" *ngFor="let file of filesToUpload; let i = index">
                                                <img [src]="file.data" width="45px" alt="file">
                                                <div class="info">
                                                    <h4 class="name">
                                                        {{ file?.name }}
                                                    </h4>
                                                    <app-progress [progress]="file?.progress"></app-progress>
                                                </div>
                                                <img src="assets/images/ic-delete-file.svg" class="delete" width="20px"
                                                    alt="file" (click)="deleteFileToUpload(i)">
                                            </div>
                                        </div>
                                        <div class="files-list">
                                            <h3>Files To Delete</h3>
                                            <div class="single-file" *ngFor="let file of filesToDelete; let i = index">
                                                <img [src]="file.data" width="45px" alt="file">
                                                <div class="info">
                                                    <h4 class="name">
                                                        {{ file?.name }}
                                                    </h4>
                                                    <app-progress [progress]="file?.progress"></app-progress>
                                                </div>
                                                <img src="assets/images/ic-delete-file.svg" class="delete" width="20px"
                                                    alt="file" (click)="restoreFile(i)">
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                    <div class="pull-right">
                        <button type="button" class="btn btn-primary" [disabled]="disabled"
                            (click)="update()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->