import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from '../entities/order';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpService) { }

  all(query: string = null): Observable<Order[]> {
    return <Observable<Order[]>>(this.http.get("orders?" + query))
  }

  add(data: any) {
    return this.http.post("orders", data)
  }

  update(id: number, object: object) {
    return this.http.put('orders/' + id, object)
  }

  get(id: number) {
    return this.http.get('orders/' + id)
  }
}
