import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Coupon } from '../entities/coupon';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CouponService {

  constructor(private http: HttpService) { }

  all(query: string = null): Observable<Coupon[]> {
    return <Observable<Coupon[]>>(this.http.get("coupons?" + query))
  }

  add(data: any) {
    return this.http.post("coupons", data)
  }

  update(id: number, object: object) {
    return this.http.put('coupons/' + id, object)
  }

  delete(id: number) {
    return this.http.delete('coupons/' + id)
  }

  get(id: number) {
    return this.http.get('coupons/' + id)
  }
}
