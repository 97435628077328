import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { Message } from 'src/app/entities/message';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, displayWarning, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { environment } from 'src/environments/environment';
import { getAllMessages, getSelectedMessageId } from '../../messages/store/message.selectors';
import { updateMessage } from '../store/message.actions';
import { getSelectedMessage } from '../store/message.selectors';

@Component({
  selector: 'app-update-message',
  templateUrl: './update-message.component.html',
  styleUrls: ['./update-message.component.scss'],
})
export class UpdateMessageComponent implements OnInit {

  selected: Message;
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router,
    private attachmentService: AttachmentService
  ) { }


  ngOnInit() {
    this.store.dispatch(setLoadingSpinner({ status: true }))
    this.$selected.subscribe((message: Message) => {
      this.store.dispatch(setLoadingSpinner({ status: false }))
      if (message == null)
        this.router.navigateByUrl('/messages/list-message');
      else {
        this.selected = message;
        // if (!this.selected.readed)
          this.update();
      }
    })

  }

  update() {

    this.disabled = true;
    let message: any = {
      readed: true,
    };
    this.store.dispatch(updateMessage({ update: { id: this.selected.id, changes: message } }));
    this.disabled = false;

  }
  disabled = false;
  /**
   * Getter $selected
   * @return { Observable<Message> }
   */
  public get $selected(): Observable<Message> {
    return this.store.select(getSelectedMessage);
  }
  /**
   * Getter $selected
   * @return { Observable<Message> }
   */
  public get $selectedId(): Observable<number> {
    return this.store.select(getSelectedMessageId);
  }
}
