import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getCurrentUser } from 'src/app/components/auth/store/auth.selectors';
import { User } from 'src/app/entities/user';
import { NavService, Menu } from '../../service/nav.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {

  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  currentUser: User;
  constructor(private router: Router, public navServices: NavService, private store: Store) {
    this.navServices.items.subscribe(
      (menuItems) => {
        console.log(menuItems); this.menuItems = menuItems;
        this.removeTabs();
      },
      (error) => { console.log(error) },
      () => {
        console.log(this.menuItems)

      })
  }
  ngOnInit() {
  }
  removeTabs() {
    this.removeRestrictedTabs().then(() => {
      console.log(this.menuItems)
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.menuItems.filter(items => {
            if (items.path === event.url)
              this.setNavActive(items)
            if (!items.children) return false
            items.children.filter(subItems => {
              if (subItems.path === event.url)
                this.setNavActive(subItems)
              if (!subItems.children) return false
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url)
                  this.setNavActive(subSubItems)
              })
            })
          })
        }
      })
    })
  }
  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem != item)
        menuItem.active = false
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true
            submenuItems.active = true
          }
        })
      }
    })
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item))
          a.active = false
        if (!a.children) return false
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false
          }
        })
      });
    }
    item.active = !item.active
  }

  //Fileupload
  readUrl(event: any) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    }
  }

  removeRestrictedTabs(): Promise<Boolean> {
    let items = [
      {
        tab: 'Category',
        tabPlural: 'Categories',
        object: 'category'
      },
      {
        tab: 'Product',
        tabPlural: 'Products',
        object: 'product'
      },
      {
        tab: 'Store',
        tabPlural: 'Stores',
        object: 'store'
      },
      {
        tab: 'Role',
        tabPlural: 'Roles',
        object: 'role'
      },
      {
        tab: 'Coupon',
        tabPlural: 'Coupons',
        object: 'coupon'
      },
      {
        tab: 'User',
        tabPlural: 'Users',
        object: 'user'
      },
      {
        tab: 'Sale',
        tabPlural: 'Sales',
        object: 'order'
      },
      // {
      //   tab: 'Report',
      //   tabPlural: 'Reports',
      //   object: 'report'
      // },
    ]
    console.log(items)
    return new Promise<any>((resolve, reject) => {
      this.$currentUser.subscribe(
        user => {
          this.currentUser = user;
          items.forEach((item, index) => {
            let permissions = {
              create: this.currentUser.role.permissions.find(permission => permission.name == 'create_' + item.object).is_permitted,
              showAll: this.currentUser.role.permissions.find(permission => permission.name == 'show_all_' + item.object).is_permitted
            };
            if (!permissions.showAll && !permissions.create) {
              this.menuItems = this.menuItems.filter(menuItem => menuItem != this.menuItems.find(menuItem => menuItem.title == item.tabPlural))
            } else if (!permissions.showAll) {
              if (item.tab == 'Sale') {
                this.menuItems = this.menuItems.filter(menuItem => menuItem != this.menuItems.find(menuItem => menuItem.title == item.tabPlural))
              }
              else this.menuItems.find(menuItem => menuItem.title == item.tabPlural).children = this.menuItems.find(menuItem => menuItem.title == item.tabPlural).children.filter((children) => children.title != item.tab + ' List')
            }
            else if (!permissions.create) {
              if (item.tab != 'Sale')
                this.menuItems.find(menuItem => menuItem.title == item.tabPlural).children = this.menuItems.find(menuItem => menuItem.title == item.tabPlural).children.filter((children) => children.title != 'Create ' + item.tab)
            }
            if (index == items.length - 1) {
              console.log(this.menuItems);
              resolve(true)
            }
          })
        },
        error => reject(error),
        () => {

        })
    })
  }
  // ngOnInit(){}
  /**
   * Getter $currentUser
   * @return { Observable<User> }
   */
  public get $currentUser(): Observable<User> {
    return this.store.select(getCurrentUser);
  }
}
