import { Injectable } from '@angular/core';
import { Role } from '../entities/role';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private roles: Role[] = [];
  private selected: Role | null = null;
  constructor(private http: HttpService) {
  }

  all(query: string) {
    return this.http.get('roles?' + query);

  }
  add(role: any) {
    return this.http.post('roles', role);
  }
  update(id: number, object: object) {
    return this.http.put('roles/' + id, object)
  }

  delete(id: number) {
    return this.http.delete('roles/' + id)
  }

  get(id: number) {
    return this.http.get('roles/' + id)
  }

  /**
   * Getter $roles
   * @return { Role[] }
   */
  public get $roles(): Role[] {
    return this.roles;
  }
  /**
  * Setter $roles
  * @param { Role[] } value
  */
  public set $roles(value: Role[]) {
    this.roles = value;
  }
  /**
   * Getter $selected
   * @return { Role | null }
   */
  public get $selected(): Role | null {
    return this.selected;
  }
  /**
  * Setter $selected
  * @param { Role | null } value
  */
  public set $selected(value: Role | null) {
    this.selected = value;
  }
}
