import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListRoleComponent } from './list-role/list-role.component';
import { CreateRoleComponent } from './create-role/create-role.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { RolesResolver } from './roles.resolver';
import { UpdateRoleComponent } from './update-role/update-role.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-role',
        component: ListRoleComponent,
        resolve: {
          roles: RolesResolver
        },
        data: {
          title: "Role List",
          breadcrumb: "Role List"
        }
      },
      {
        path: 'create-role',
        component: CreateRoleComponent,
        data: {
          title: "Create Role",
          breadcrumb: "Create Role"
        }
      },
      {
        path: 'update-role',
        component: UpdateRoleComponent,
        data: {
          title: "Manage Role Permissions",
          breadcrumb: "Manage Role Permissions"
        }
      },
      {
        path: 'permissions',
        component: PermissionsComponent,
        data: {
          title: "Manage Role Permissions",
          breadcrumb: "Manage Role Permissions"
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RolesRoutingModule { }
