import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Order } from 'src/app/entities/order';


export const loadOrders = createAction(
  '[Orders List] Load Orders via Service',
);

export const ordersLoaded = createAction(
  '[Orders Effect] Orders Loaded Successfully',
  props<{ orders: Order[] }>()
);

export const createOrder = createAction(
  '[Create Order Component] Create Order',
  props<{ order: Order }>()
);

export const createOrderSuccess = createAction(
  '[create Order Success] create Order Success',
  props<{ order: Order }>()
);
export const storeOrder = createAction(
  '[create Order Success] store Order Success',
  props<{ order: Order }>()
);
export const updateOrderSuccess = createAction(
  '[update Order Success] update Order Success',
  props<{ order: Update<Order> }>()
);
export const changeOrder = createAction(
  '[update Order Success] change Order Success',
  props<{ order: Update<Order> }>()
);

export const updateOrder = createAction(
  '[Orders List Operations] Update Order',
  props<{ update: Update<Order> }>()
);
export const startUpdateOrder = createAction(
  '[Update Order Operations] Start Update',
  props<{ orderId: number }>()
);


export const orderActionTypes = {
  loadOrders,
  ordersLoaded,
  createOrder,
  updateOrder,
  startUpdateOrder,
  createOrderSuccess,
  updateOrderSuccess,
  storeOrder,
  changeOrder,
};