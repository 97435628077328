import { itemActionTypes, itemsLoaded, updateItem, deleteItem, updateItemSuccess, createItemSuccess } from './item.actions';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/services/item.service';
import { Item } from 'src/app/entities/item';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, setConfirmDeleteModalState, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { from, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { getAllCategories } from '../../categories/store/category.selectors';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class ItemEffects {

  loadItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(itemActionTypes.loadItems),
      concatMap(() => this.itemService.all((AuthService.market?.name != 'Medina Market' ? 'store_id=' + AuthService.market?.id + '&' : '') + "with=creator,editor,category,store")),
      map((items: Item[]) => itemActionTypes.itemsLoaded({ items })),
      tap(() => { this.store.dispatch(setLoadingSpinner({ status: false })) }),
      // tap(() => { this.router.navigateByUrl('/products/list-product') })
    )
  });

  createItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(itemActionTypes.createItem),
      concatMap((action) => this.itemService.add(action.item)),
      map((item: Item) => {
        this.store.select(getCurrentUser).subscribe((user) => {
          item.creator = user;
          this.store.select(getAllCategories).subscribe((categories) => {
            categories.map((cat) => {
              if (cat.id == <number>(item.item_category_id)) {
                if (!Object.isFrozen(item))
                  item.category = cat;
                this.store.dispatch(createItemSuccess({ item }))
              }
            })
          })
        })
      }),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Item Creation failed'
          }))
        return of();
      })
    )


  }, { dispatch: false }
  );

  deleteItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(itemActionTypes.deleteItem),
      exhaustMap(
        (action) => {
          return this.itemService.delete(action.itemId).pipe(
            tap(() => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              this.store.dispatch(displaySuccess({ message: 'Item Deleted Successfully!', title: '' }));
            }),
            catchError((errResp: any) => {
              this.store.dispatch(setLoadingSpinner({ status: false }));
              if (errResp != null)
                this.store.dispatch(displayError({
                  message: JSON.stringify(errResp.error.message), title: 'Item Delete failed'
                }))
              return of();
            })
          );
        })

    )
  }, { dispatch: false });

  updateStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(itemActionTypes.startUpdateItem),
      tap(() => this.router.navigateByUrl('/products/update-product'))
    )
  }, { dispatch: false });

  deleteStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(itemActionTypes.startDeleteItem),
      exhaustMap((action) => this.runDialog(ConfirmDeleteComponent, action.itemId)))
  }, { dispatch: false });

  runDialog = (content, itemId) => {
    const modalRef = this.modalService.open(content, { centered: true });
    modalRef.componentInstance.onConfirm = () => {
      this.store.dispatch(deleteItem({ itemId }))
    };
    return from(modalRef.result);
  };
  updateItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(itemActionTypes.updateItem),
      concatMap((action) => this.itemService.update(<number>action.update.id, action.update.changes)),
      map((item: Item) => {
        this.store.select(getCurrentUser).subscribe((user) => {
          item.editor = user;
          this.store.select(getAllCategories).subscribe((categories) => {
            categories.map((cat) => {
              if (cat.id == <number>(item.item_category_id)) {
                if (!Object.isFrozen(item))
                  item.category = cat;
                this.store.dispatch(updateItemSuccess({ item: { id: item.id, changes: item } }))
              }
            })
          })
        })
      }),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Item Update failed'
          }))
        return of();
      })
    )

  }, { dispatch: false });
  createItemSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(itemActionTypes.createItemSuccess),
      tap(() => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.store.dispatch(displaySuccess({ message: 'Item Created Successfully!', title: '' }))
        this.router.navigateByUrl('/products/list-product');
      })
    )
  }, { dispatch: false })
  updateItemSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(itemActionTypes.updateItemSuccess),
      tap(() => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.store.dispatch(displaySuccess({ message: 'Item Updated Successfully!', title: '' }))
        this.router.navigateByUrl('/products/list-product');
      })
    )
  }, { dispatch: false })
  constructor(private itemService: ItemService, private actions$: Actions, private router: Router, private store: Store<AppState>, private modalService: NgbModal) { }
}