import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private http: HttpService, private httpClient: HttpClient) {
  }

  all(query: string = null)
  {
    return this.http.get("attachments?" + query)
  }

  upload(data : any)
  {
    return this.http.post('attachments', data);
  }

  delete( name: string)
  {
    return this.http.delete('attachments/'+ name)
  }

  download(name: string)
  {
    return this.httpClient.get('attachments/download/' + name, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    });
  }
}
