import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { AuthState, AuthReducer } from 'src/app/components/auth/store/auth.reducers';
import { AUTH_STATE_NAME } from 'src/app/components/auth/store/auth.selectors';
import { environment } from '../../../environments/environment';
import { SharedReducer } from '../Shared/shared.reducer';
import { SHARED_STATE_NAME } from '../Shared/shared.selector';
import { SharedState } from '../Shared/shared.state';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';


export interface AppState {
  [SHARED_STATE_NAME]: SharedState;
  [AUTH_STATE_NAME]: AuthState;
  router: RouterReducerState;
}

export const reducers: ActionReducerMap<AppState> = {
  [SHARED_STATE_NAME]: SharedReducer,
  [AUTH_STATE_NAME]: AuthReducer,
  router: routerReducer,
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];
