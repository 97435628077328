import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Category } from 'src/app/entities/category';
import { Item } from 'src/app/entities/item';
import { Market } from 'src/app/entities/market';
import { Role } from 'src/app/entities/role';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { environment } from 'src/environments/environment';
import { getAllCategories } from '../../categories/store/category.selectors';
import { getAllMarkets } from '../../markets/store/market.selectors';
import { getAllRoles } from '../../roles/store/role.selectors';
import { createItem } from '../store/item.actions';
import { getAllItems } from '../store/item.selectors';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.scss'],
})
export class CreateItemComponent implements OnInit {
  public itemForm: FormGroup = new FormGroup(
    {
      name: new FormControl('', Validators.required),
      price: new FormControl('', Validators.required),
      quantity: new FormControl('', Validators.required),
      old_price: new FormControl(''),
      height: new FormControl(''),
      width: new FormControl(''),
      length: new FormControl(''),
      weight: new FormControl(''),
      category: new FormControl('', Validators.required),
      market: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      color: new FormControl('', Validators.required),
      is_trending: new FormControl({ defaultValue: false }, Validators.required),

    }
  );
  public imageForm: FormGroup = new FormGroup(
    {
      image: new FormControl('')
    }
  );
  constructor(
    private toastr: ToastrService,
    private store: Store<AppState>,
    private attachmentService: AttachmentService
  ) { }

  ngOnInit() {
    if (AuthService.market.name != 'Medina Market') {
      this.itemForm.patchValue({
        market: AuthService.market.id
      })
      this.itemForm.controls['market'].disable()
    }
    this.itemForm.controls.is_trending.patchValue(false);

  }

  add() {
    if (this.itemForm.invalid || this.files.length == 0) {
      if (this.itemForm.controls.name.invalid) {
        this.toastr.error('The item name field is required !', 'item name is required');
      }
      if (this.itemForm.controls.market.invalid) {
        this.toastr.error('The item store field is required !', 'item store is required');
      }
      if (this.itemForm.controls.price.invalid) {
        this.toastr.error('The item price field is required !', 'item price is required');
      }
      if (this.itemForm.controls.category.invalid) {
        this.toastr.error('The item category field is required !', 'item category is required');
      }
      if (this.itemForm.controls.quantity.invalid) {
        this.toastr.error('The item quantity field is required !', 'item quantity is required');
      }
      if (this.itemForm.controls.old_price.invalid) {
        this.toastr.error('The item old price field is required !', 'item old price is required');
      }
      // if (this.itemForm.controls.width.invalid) {
      //   this.toastr.error('The item width field is required !', 'item width is required');
      // }
      // if (this.itemForm.controls.height.invalid) {
      //   this.toastr.error('The item height field is required !', 'item height is required');
      // }
      // if (this.itemForm.controls.length.invalid) {
      //   this.toastr.error('The item length field is required !', 'item length is required');
      // }
      // if (this.itemForm.controls.weight.invalid) {
      //   this.toastr.error('The item weight field is required !', 'item weight is required');
      //}
      if (this.itemForm.controls.color.invalid) {
        this.toastr.error('The item color field is required !', 'item color is required');
      }
      if (this.itemForm.controls.is_trending.invalid) {
        this.toastr.error('The item trending field is required !', 'item trending is required');
      }
      if (this.files.length == 0) {
        this.toastr.error('The item pictures are required !', 'item pictures are required');
      }
    } else {
      if (this.files.length != 0) {
        this.fileDropEl.nativeElement.value = "";
        this.uploadFiles().then(() => {
          let item: any = {
            name: this.itemForm.value.name,
            price: this.itemForm.value.price,
            description: this.itemForm.value.description,
            item_category_id: this.itemForm.value.category.value == '' ? null : this.itemForm.value.category,
            store_id: AuthService.market?.name == 'Medina Market' ? (this.itemForm.value.market.value == '' ? null : this.itemForm.value.market) : AuthService.market?.id,
            old_price: this.itemForm.value.old_price,
            width: this.itemForm.value.width,
            height: this.itemForm.value.height,
            length: this.itemForm.value.length,
            weight: this.itemForm.value.weight,
            quantity: this.itemForm.value.quantity,
            color: this.itemForm.value.color,
            is_trending: this.itemForm.value.is_trending,
            photo: JSON.stringify(this.photo)
          };
          this.store.dispatch(setLoadingSpinner({ status: true }))
          this.store.dispatch(createItem({ item }))
        }, (error) => {
        });
      }
      else {
        let item: any = {
          name: this.itemForm.value.name,
          price: this.itemForm.value.price,
          description: this.itemForm.value.description,
          item_category_id: this.itemForm.value.category.value == '' ? null : this.itemForm.value.category,
          store_id: this.itemForm.value.market.value == '' ? null : this.itemForm.value.market,
          old_price: this.itemForm.value.old_price,
          width: this.itemForm.value.width,
          heigth: this.itemForm.value.heigth,
          length: this.itemForm.value.length,
          weigth: this.itemForm.value.weigth,
          quantity: this.itemForm.value.quantity,
          color: this.itemForm.value.color,
          is_trending: this.itemForm.value.is_trending,
          photo: null
        };
        this.store.dispatch(setLoadingSpinner({ status: true }))
        this.store.dispatch(createItem({ item }))
      }
    }
  }
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  files: any[] = [];
  photo: any[] = [];
  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files.length >= index) {
      this.files.splice(index, 1);
    }
  }

  src(name) {
    return environment.baseUrl + "attachments/download/" + name
  }
  /**
   * Simulate the upload process
   */
  uploadFiles(): Promise<any> {
    if (this.files.length == 0) {
      return;
    }
    return new Promise((resovle, reject) => {
      this.files.map((file, index) => {
        console.log(file);
        this.attachmentService.upload({ image: file.data, ext: file.ext, object_type: 'item', index: index })
          .subscribe(
            (result: any) => {
              this.photo.push(result.image);
              setTimeout(() => {
                const progressInterval = setInterval(() => {
                  if (file.progress === 100) {
                    clearInterval(progressInterval);
                  } else {
                    if (file.progress != 95) file.progress += 5;
                  }
                }, 200);
              }, 1000);
            },
            (error: Error) => {
              this.store.dispatch(displayError({ message: error.message, title: '' }))
              reject(error);
            },
            () => {
              file.progress = 100;
              this.store.dispatch(displaySuccess({ title: 'Image uploaded successfully ! ', message: '' }))
              if (this.files.length - 1 == index) {
                resovle(true);
              }
            }
          )


      })
    });
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {


    let finished = false;
    let index = 0
    while (!finished) {
      finished = this.readFile(files, index);
      index++;
    }
  }
  readFile(files, index) {
    if (index === files.length) {
      return true;
    }
    let ext = files[index].name.substr(files[index].name.lastIndexOf('.') + 1, files[index].name.length);
    if (ext == 'png' || ext == 'jpg' || ext == 'webp' || ext == 'svg' || ext == 'jpeg') {
      var reader = new FileReader();
      reader.readAsDataURL(<File>files[index]);
      reader.onloadend = (_event) => {
        files[index].progress = 0;
        files[index].data = reader.result;
        files[index].ext = ext;
        this.files.push(files[index]);
        return false;
      };
    }
    else this.store.dispatch(displayError({ message: 'Only Images of type png, jpg, webp, svg or jpeg are allowed', title: 'Invalid Image Type [' + ext + ']' }))
  }
  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  /**
   * Getter $categories
   * @return { Observable<Category[]> }
   */
  public get $categories(): Observable<Category[]> {
    return this.store.select(getAllCategories).pipe(
      map((categories) => categories.filter(category => category.parent_id != null))
    );
  }
  /**
   * Getter $markets
   * @return { Observable<Market[]> }
   */
  public get $markets(): Observable<Market[]> {
    return this.store.select(getAllMarkets);
  }
}
