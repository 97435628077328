import { ItemState, selectAll, selectedItemId } from './item.reducers';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export const itemFeatureSelector = createFeatureSelector<ItemState>('items');

export const getAllItems = createSelector(
  itemFeatureSelector,
  selectAll
);
export const getSelectedItemId = createSelector(
  itemFeatureSelector,
  state => state.selectedItemId
);

export const getSelectedItem = createSelector(
  itemFeatureSelector,
  state => state.selectedItemId != null ? state.entities[state.selectedItemId] : null
);

export const areItemsLoaded = createSelector(
  itemFeatureSelector,
  state => state.itemsLoaded
);