import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Role } from 'src/app/entities/role';
import { SidebarComponent } from 'src/app/shared/components/sidebar/sidebar.component';
import { roleActionTypes, rolesLoaded } from './role.actions';

export interface RoleState extends EntityState<Role> {
  rolesLoaded: boolean;
  selectedRoleId: number;
}

export const adapter: EntityAdapter<Role> = createEntityAdapter<Role>({
  // sortComparer: (a: Role, b: Role) => b.updated_at.localeCompare(<string>a.updated_at),
});

export const initialState = adapter.getInitialState({
  rolesLoaded: false,
  selectedRoleId: null
});

export const roleReducer = createReducer(
  initialState,

  on(roleActionTypes.rolesLoaded, (state, action) => {
    return adapter.setAll(
      action.roles,
      { ...state, rolesLoaded: true }
    );
  }),

  on(roleActionTypes.createRoleSuccess, (state, action) => {
    return adapter.addOne(action.role, state);
  }),

  on(roleActionTypes.deleteRole, (state, action) => {
    return adapter.removeOne(action.roleId, state);
  }),

  on(roleActionTypes.updateRoleSuccess, (state, action) => {
    console.log(action);
    return adapter.updateOne(action.role, { ...state, selectedRoleId: null });
  }),
  on(roleActionTypes.startUpdateRole, (state, action) => {
    return { ...state, selectedRoleId: action.roleId }
  }),
  on(roleActionTypes.startDeleteRole, (state, action) => {
    return { ...state, selectedRoleId: action.roleId }
  }),

);

export const { selectAll } = adapter.getSelectors();

export const selectedRoleId = (state) => state.selectedRoleId;
