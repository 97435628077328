import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { Observable } from 'rxjs';
import { Market } from 'src/app/entities/market';
import { Role } from 'src/app/entities/role';
import { User } from 'src/app/entities/user';
import { AppState } from 'src/app/store/reducers';
import { startDeleteUser, startUpdateUser } from '../store/user.actions';
import { getAllUsers } from '../store/user.selectors';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {
  public user_list = []
  users: User[] = []
  @ViewChild('table')
  smartTable: Ng2SmartTableComponent;

  constructor(
    private store: Store<AppState>
  ) {
  }

  public settings = {
    actions: {
      position: 'left',
      custom: [
        {
          name: 'update_user',
          title: '<i class="fa fa-pencil" title="Update Users"></i>'
        },
        {
          name: 'delete_user',
          title: '<i class="fa fa-trash" title="Detele Users"></i>'
        }
      ],
      edit: false,
      delete: false,
      add: false
    },
    columns: {
      // avatar: {
      //   title: 'Avatar',
      //   type: 'html'
      // },
      first_name: {
        title: 'First Name',
      },
      last_name: {
        title: 'Last Name'
      },
      email: {
        title: 'Email'
      },
      phone: {
        title: 'Phone'
      },
      store: {
        title: 'Store',
        valuePrepareFunction: (store) => {
          return store?.name;
        },
        filterFunction(market?: Market, search?: string): boolean {
          return market?.name?.toLowerCase()?.indexOf(search?.toLowerCase()) > -1 || search === '';
        },
        compareFunction: (direction: any, a: Market, b: Market): number => {
          return a != null ? b != null ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 * direction : a.name.toLowerCase() > b.name.toLowerCase() ? direction : 0 : direction : -1 * direction;
        }
      },
      role: {
        title: 'Role',
        valuePrepareFunction: (role) => {
          return role?.name;
        },
        filterFunction(role?: Role, search?: string): boolean {
          return role?.name?.toLowerCase()?.indexOf(search?.toLowerCase()) > -1 || search === '';
        },
        compareFunction: (direction: any, a: Role, b: Role): number => {
          return a != null ? b != null ? a.name.toLowerCase() < b.name.toLowerCase() ? -1 * direction : a.name.toLowerCase() > b.name.toLowerCase() ? direction : 0 : direction : -1 * direction;
        }
      },
      gender: {
        title: 'Gender',
        type: 'html',

        valuePrepareFunction: (gender) => {
          return gender == 'F' ? '<span class="fa fa-venus"></span>' : '<span class="fa fa-mars"></span>';
        },
      },
      inactive: {
        title: 'Status',
        type: 'html',

        valuePrepareFunction: (value) => {
          return value ? '<span class="badge badge-pill badge-warning">Banned</span>' : '<span class="badge badge-pill badge-success">Active</span>';
        },
        filterFunction(inactive?: number, search?: string): boolean {
          console.log(inactive)
          return (inactive === 0 && 'active'.toLowerCase()?.indexOf(search?.toLowerCase()) > -1) || (inactive === 1 && 'banned'.toLowerCase()?.indexOf(search?.toLowerCase()) > -1) ||search === '';
        },
      },
      email_verified_at: {
        title: 'Verified at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        },
        filterFunction(date?: string, search?: string): boolean {
          return new Date(date).toLocaleString().indexOf(search?.toLowerCase()) > -1 || search === '';
        }
      },
      creator: {
        title: 'Created by',
        valuePrepareFunction: (creator) => {
          return creator?.extra?.full_name;
        },
        filterFunction(creator?: User, search?: string): boolean {
          return creator?.extra?.full_name?.toLowerCase()?.indexOf(search?.toLowerCase()) > -1 || search === '';
        },
        compareFunction: (direction: any, a: User, b: User): number => {
          return a != null ? b != null ? a.extra.full_name.toLowerCase() < b.extra.full_name.toLowerCase() ? -1 * direction : a.extra.full_name.toLowerCase() > b.extra.full_name.toLowerCase() ? direction : 0 : direction : -1 * direction;
        }
      },
      editor: {
        title: 'Updated by',
        valuePrepareFunction: (editor) => {
          return editor?.extra?.full_name;
        },
        filterFunction(creator?: User, search?: string): boolean {
          return creator?.extra?.full_name?.toLowerCase()?.indexOf(search?.toLowerCase()) > -1 || search === '';
        },
        compareFunction: (direction: any, a: User, b: User): number => {
          return a != null ? b != null ? a.extra.full_name.toLowerCase() < b.extra.full_name.toLowerCase() ? -1 * direction : a.extra.full_name.toLowerCase() > b.extra.full_name.toLowerCase() ? direction : 0 : direction : -1 * direction;
        }
      },
      created_at: {
        title: 'Joining Date',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        },
        filterFunction(date?: string, search?: string): boolean {
          return new Date(date).toLocaleString().indexOf(search?.toLowerCase()) > -1 || search === '';
        }
      },
      updated_at: {
        title: 'Updated at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        },
        filterFunction(date?: string, search?: string): boolean {
          return new Date(date).toLocaleString().indexOf(search?.toLowerCase()) > -1 || search === '';
        }
      },
    },
  };

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.smartTable.custom.subscribe((dataObject: any) => {
      switch (dataObject.action) {
        case "update_user":
          this.store.dispatch(startUpdateUser({ userId: (<User>dataObject.data).id }))
          break;
        case 'delete_user':
          this.store.dispatch(startDeleteUser({ userId: (<User>dataObject.data).id }))
          break;
      }
    })
  }
  /**
   * Getter $users
   * @return { Observable<User[]> }
   */
  public get $users(): Observable<User[]> {
    return this.store.select(getAllUsers);
  }

}

