import { loginSuccess, autoLogout, loginFail } from './auth.actions';
import { createReducer, on } from '@ngrx/store';
import { User } from 'src/app/entities/user';

export interface AuthState {
  currentUser: User | null;
}

export const initialState: AuthState = {
  currentUser: null,
};

const _authReducer = createReducer(
  initialState,
  on(loginSuccess, (state, action) => {
    console.log(action)
    return {
      ...state,
      currentUser: action.user,
    };
  }),
  on(loginFail, (state) => {
    return {
      ...state,
      currentUser: null
    };
  }),
  on(autoLogout, (state) => {
    return {
      ...state,
      currentUser: null,
    };
  })
);

export function AuthReducer(state, action) {
  return _authReducer(state, action);
}
