import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
  public permissionForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.createPermissionForm();
  }

  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    })
  }

  ngOnInit(): void {
  }

}
