import { orderActionTypes, updateOrderSuccess, createOrderSuccess } from './order.actions';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { Order } from 'src/app/entities/order';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/reducers';
import { displayError, displaySuccess, setLoadingSpinner } from 'src/app/store/Shared/shared.actions';
import { from, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDeleteComponent } from 'src/app/shared/components/confirm-delete/confirm-delete.component';


@Injectable()
export class OrderEffects {

  loadOrders$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActionTypes.loadOrders),
      concatMap(() => this.orderService.all("with=creator,editor,customer")),
      map((orders: Order[]) => orderActionTypes.ordersLoaded({ orders })),
      tap(() => { this.store.dispatch(setLoadingSpinner({ status: false })) }),
    )
  });   

  createOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActionTypes.createOrder),
      concatMap((action) => this.orderService.add(action.order)),
      map((order: Order) => {
        this.store.dispatch(createOrderSuccess({ order }))
      }),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Order Creation failed'
          }))
        return of();
      })
    )


  }, { dispatch: false }
  );

  
  updateStart$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActionTypes.startUpdateOrder),
      tap(() => this.router.navigateByUrl('/orders/update-orders'))
    )
  }, { dispatch: false });

 
  updateOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActionTypes.updateOrder),
      concatMap((action) => this.orderService.update(<number>action.update.id, action.update.changes)),
      map((order: Order) => this.store.dispatch(updateOrderSuccess({ order: { id: order.id, changes: order } }))),
      catchError((errResp: any) => {
        this.store.dispatch(setLoadingSpinner({ status: false }));
        if (errResp != null)
          this.store.dispatch(displayError({
            message: errResp.error.hasOwnProperty('email') ?
              JSON.stringify(errResp.error.email[0])
              : errResp.error.hasOwnProperty('password') ?
                JSON.stringify(errResp.error.password[0])
                : errResp.error.hasOwnProperty('first_name') ?
                  JSON.stringify(errResp.error.first_name[0])
                  : errResp.error.hasOwnProperty('last_name') ?
                    JSON.stringify(errResp.error.last_name[0])
                    : errResp.error.hasOwnProperty('role_id') ?
                      JSON.stringify(errResp.error.role_id[0])
                      : JSON.stringify(errResp.error.message), title: 'Order Update failed'
          }))
        return of();
      })
    )

  }, { dispatch: false });
  createOrderSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActionTypes.createOrderSuccess),
      tap(() => {
        this.store.dispatch(displaySuccess({ message: 'Order Created Successfully!', title: '' }))
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.router.navigateByUrl('/orders/list-orders');
      })
    )
  }, { dispatch: false })
  updateOrderSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderActionTypes.updateOrderSuccess),
      tap(() => {
        this.store.dispatch(displaySuccess({ message: 'Order Updated Successfully!', title: '' }))
        this.store.dispatch(setLoadingSpinner({ status: false }));
        this.router.navigateByUrl('/orders/list-orders');
      })
    )
  }, { dispatch: false })
  constructor(private orderService: OrderService, private actions$: Actions, private router: Router, private store: Store<AppState>, private modalService: NgbModal) { }
}