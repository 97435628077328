import { areMarketsLoaded } from './store/market.selectors';
import { loadMarkets, marketsLoaded } from './store/market.actions';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';
import { setLoadingSpinner } from 'src/app/store/Shared/shared.actions';

@Injectable()
export class MarketsResolver implements Resolve<Observable<any>> {

  constructor(private store: Store<AppState>) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store
      .pipe(

        select(areMarketsLoaded),
        
        tap((marketsLoaded) => {
          if (!marketsLoaded) {
            this.store.dispatch(setLoadingSpinner({ status: true }))
            this.store.dispatch(loadMarkets());
          }
        }),
        filter(marketsLoaded => marketsLoaded),
        first()
      );
  }
}
