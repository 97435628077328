import { areRolesLoaded } from './store/role.selectors';
import { loadRoles, rolesLoaded } from './store/role.actions';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { AppState } from 'src/app/store/reducers';

@Injectable()
export class RolesResolver implements Resolve<Observable<any>> {

  constructor(private store: Store<AppState>) { 
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.store
      .pipe(
        select(areRolesLoaded),
        tap((rolesLoaded) => {
          if (!rolesLoaded) {
            this.store.dispatch(loadRoles());
          }
        }),
        filter(rolesLoaded => rolesLoaded),
        first()
      );
  }
}
