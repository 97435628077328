import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Coupon } from 'src/app/entities/coupon';


export const loadCoupons = createAction(
  '[Coupons List] Load Coupons via Service',
);

export const couponsLoaded = createAction(
  '[Coupons Effect] Coupons Loaded Successfully',
  props<{ coupons: Coupon[] }>()
);

export const createCoupon = createAction(
  '[Create Coupon Component] Create Coupon',
  props<{ coupon: Coupon }>()
);

export const createCouponSuccess = createAction(
  '[create Coupon Success] create Coupon Success',
  props<{ coupon: Coupon }>()
);
export const storeCoupon = createAction(
  '[create Coupon Success] store Coupon Success',
  props<{ coupon: Coupon }>()
);
export const updateCouponSuccess = createAction(
  '[update Coupon Success] update Coupon Success',
  props<{ coupon: Update<Coupon> }>()
);
export const changeCoupon = createAction(
  '[update Coupon Success] change Coupon Success',
  props<{ coupon: Update<Coupon> }>()
);
export const deleteCouponSuccess = createAction(
  '[delete Coupon Success] delete Coupon Success',
  props<{ coupon: Coupon }>()
);
export const deleteCoupon = createAction(
  '[Coupons List Operations] Delete Coupon',
  props<{ couponId: number }>()
);
export const removeCoupon = createAction(
  '[Coupons List Operations] Remove Coupon',
  props<{ couponId: number }>()
);

export const startDeleteCoupon = createAction(
  '[Coupons List Operations] Start Delete Coupon',
  props<{ couponId: number }>()
);

export const updateCoupon = createAction(
  '[Coupons List Operations] Update Coupon',
  props<{ update: Update<Coupon> }>()
);
export const startUpdateCoupon = createAction(
  '[Update Coupon Operations] Start Update',
  props<{ couponId: number }>()
);


export const couponActionTypes = {
  loadCoupons,
  couponsLoaded,
  createCoupon,
  startDeleteCoupon,
  deleteCoupon,
  updateCoupon,
  startUpdateCoupon,
  deleteCouponSuccess,
  createCouponSuccess,
  updateCouponSuccess,
  storeCoupon,
  changeCoupon,
  removeCoupon
};