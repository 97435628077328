import { MarketState, selectAll, selectedMarketId } from './market.reducers';
import { createSelector, createFeatureSelector } from '@ngrx/store';

export const marketFeatureSelector = createFeatureSelector<MarketState>('markets');

export const getAllMarkets = createSelector(
  marketFeatureSelector,
  selectAll
);
export const getSelectedMarketId = createSelector(
  marketFeatureSelector,
  state => state.selectedMarketId
);

export const getSelectedMarket = createSelector(
  marketFeatureSelector,
  state => state.selectedMarketId != null ? state.entities[state.selectedMarketId] : null
);

export const areMarketsLoaded = createSelector(
  marketFeatureSelector,
  state => state.marketsLoaded
);