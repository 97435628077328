import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateMarketComponent } from './create-market/create-market.component';
import { UpdateMarketComponent } from './update-market/update-market.component';
import { MarketsResolver } from './markets.resolver';
import { RolesResolver } from '../roles/roles.resolver';
import { ListMarketComponent } from './list-market/list-market.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-store',
        component: ListMarketComponent,
        resolve: {
          markets: MarketsResolver
        },
        data: {
          title: "Store List",
          breadcrumb: "Store List"
        }
      },
      {
        path: 'create-store',
        component: CreateMarketComponent,
        resolve: {
          markets: MarketsResolver
        },
        data: {
          title: "Create Store",
          breadcrumb: "Create Store"
        }
      },
      {
        path: 'update-store',
        component: UpdateMarketComponent,
        resolve: {
          markets: MarketsResolver
        },
        data: {
          title: "Update Store",
          breadcrumb: "Update Store"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MarketsResolver, RolesResolver]
})
export class MarketsRoutingModule { }
