import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Ng2SmartTableComponent } from 'ng2-smart-table';
import { Observable } from 'rxjs';
import { Message } from 'src/app/entities/message';
import { User } from 'src/app/entities/user';
import { AttachmentService } from 'src/app/services/attachment.service';
import { AppState } from 'src/app/store/reducers';
import { displayError } from 'src/app/store/Shared/shared.actions';
import { getCurrentUser } from '../../auth/store/auth.selectors';
import { startDeleteMessage, startUpdateMessage } from '../store/message.actions';
import { getAllMessages } from '../store/message.selectors';

@Component({
  selector: 'app-list-message',
  templateUrl: './list-message.component.html',
  styleUrls: ['./list-message.component.scss']
})
export class ListMessageComponent implements OnInit {
  public message_list = []
  messages: Message[] = []
  @ViewChild('table')
  smartTable: Ng2SmartTableComponent;

  constructor(
    private store: Store<AppState>,
    private attachmentService: AttachmentService
  ) {
  }

  public actions = []

  public settings = {
    actions: {
      position: 'left',
      custom: this.actions,
      edit: false,
      delete: false,
      add: false
    },
    columns: {
      full_name: {
        title: 'Full Name'
      },
      email: {
        title: 'E-mail'
      },
      phone: {
        title: 'Phone'
      },
      object: {
        title: 'Object'
      },
      readed: {
        title: 'Status',
        type: 'html',
        valuePrepareFunction: (readed) => {
          return !readed ? '<span class="badge badge-pill badge-success">New Message</span>' : '<span class="badge badge-pill badge-info">Readed</span>';
        },
      },

      created_at: {
        title: 'Created at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        },
        filterFunction(date?: string, search?: string): boolean {
          return new Date(date).toLocaleString().indexOf(search?.toLowerCase()) > -1 || search === '';
        },
      },
      updated_at: {
        title: 'Updated at',
        valuePrepareFunction: (date) => {
          return date ? new Date(date).toLocaleString() : '';
        },
        filterFunction(date?: string, search?: string): boolean {
          return new Date(date).toLocaleString().indexOf(search?.toLowerCase()) > -1 || search === '';
        }
      },
    },
  };

  ngOnInit() {
    this.actions.push({
      name: 'view_message',
      title: '<i class="fa fa-eye" title="View Message"></i>'
    })
    this.actions.push({
      name: 'delete_message',
      title: '<i class="fa fa-trash" title="Detele Messages"></i>'
    })
  }

  ngAfterViewInit() {
    this.smartTable.custom.subscribe((dataObject: any) => {
      switch (dataObject.action) {
        case "view_message":
          this.store.dispatch(startUpdateMessage({ messageId: (<Message>dataObject.data).id }))
          break;
        case 'delete_message':
          this.store.dispatch(startDeleteMessage({ messageId: (<Message>dataObject.data).id }))
          break;
      }
    })
  }
  /**
   * Getter $messages
   * @return { Observable<Message[]> }
   */
  public get $messages(): Observable<Message[]> {
    return this.store.select(getAllMessages);
  }
  /**
   * Getter $user
   * @return { Observable<User> }
   */
  public get $user(): Observable<User> {
    return this.store.select(getCurrentUser);
  }

}

